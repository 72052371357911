import {
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, Message, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";

import { GruppoTecnici } from "src/app/models/gruppoTecnici.model";
import { GruppiTecniciService } from "src/app/services/gruppiTecnici.service";
import { ModificaGruppoTecniciComponent } from "./modifica/modifica-gruppo_tecnici";
import { CreaGruppoTecniciComponent } from "./crea/crea-gruppo_tecnici";

declare const CSVBoxImporter: any;
@Component({
  selector: "gruppi_tecnici",
  templateUrl: "./gruppi_tecnici.component.html",
  styleUrls: ["./gruppi_tecnici.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class GruppiTecniciComponent implements OnInit {
  isLoadingResults = false;
  resultsLength = 0;
  gruppi: GruppoTecnici[] = [];

  public isAdmin:boolean = false;
    dialogConfig;

  @ViewChild("gruppiDT", { static: true }) gruppiDT: any;

  @HostBinding("class.mobile") isMobile: boolean;

  msgs: Message[] = [];
  ref: DynamicDialogRef;

  constructor(
    private logger: NGXLogger,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private gruppiTecniciService: GruppiTecniciService,


  ) {
    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };
  }
  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnInit(): void {
    /* if(localStorage.getItem/Users/fabiosebastiano/Repos/ates/ates-be/src/main/resources/db/migration/V1.0.34__TIPO_INTERVENTO_nuovi_valori_DUE.sql("ruolo") && localStorage.getItem("ruolo")==='ADMIN'){
      this.isAdmin = true;
    } */
    this.loadGruppi();
  }

  ngAfterViewInit() {}

  filtraPerNome(event: any){
    this.gruppiDT.filterGlobal(event.target.value, 'contains')
  }



  loadGruppi() {
    this.isLoadingResults = true;
    this.gruppiTecniciService.get().subscribe(tipiInterventoFromDB =>{
      this.gruppi = tipiInterventoFromDB;
      this.isLoadingResults = false;
    });


  }



  openCreaGruppoTecnici(){
     this.ref = this.dialogService.open(CreaGruppoTecniciComponent, {
      data: {

      },
      header: 'Crea gruppo di tecnici',
      width: this.isMobile?'90%':'40%',
      height: this.isMobile?'90%':'50%',
      baseZIndex: 10000,
      closable:true,
      draggable:false,
  });
  this.ref.onClose.subscribe(confirmNewUtente => {
    if(confirmNewUtente && confirmNewUtente.success){
      this.messageService.add({
        severity: "success",
        summary: "",
        detail: "Gruppo di Tecnici creato con successo!", life:10000
      });
      this.loadGruppi();
    }
  });
  }

  openModificaGruppoDialog(gruppoTecnici:GruppoTecnici){
      this.ref = this.dialogService.open(ModificaGruppoTecniciComponent, {
        data: {
          gruppoTecnici : gruppoTecnici
        },
        header: 'Modifica gruppo di tecnici',
        width: this.isMobile?'90%':'40%',
        height: this.isMobile?'90%':'80%',
        baseZIndex: 10000,
        closable:true,
        draggable:false,
    });
    this.ref.onClose.subscribe(confirmNewUtente => {
      if(confirmNewUtente && confirmNewUtente.success){
        this.messageService.add({
          severity: "success",
          summary: "",
          detail: "Gruppo di Tecnici modificato con successo!", life:10000
        });
        this.loadGruppi();
      }
    });
  }

  openEliminaGruppoDialog(gruppoTecnici:GruppoTecnici){
    this.confirmationService.confirm({
      message: `Confermi l\'eliminazione del gruppo tecnici: <b>${gruppoTecnici.descrizione.toUpperCase()}</b>?`,
      header: "Eliminazione Gruppo Tecnici",
      icon: "pi pi-eraser",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
        this.gruppiTecniciService.elimina(gruppoTecnici.id.toString()).subscribe({
          next: (v) => {},
          error: (e) => {
            this.messageService.add({
              severity: "info",
              summary: "Errore",
              detail: "Errore in fase di eliminazione Gruppo Tecnici", life:10000
            });
          },
          complete: () => {
            this.messageService.add({
              severity: "success",
              summary: "",
              detail: "Gruppo Tecnici eliminato", life:10000
            });
            this.loadGruppi();
          },
        });
      },
      reject: () => {},
    });
  }



}
