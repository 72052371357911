// STILI CSS

export const  STILI_STATO_SEGNALAZIONI = {
  "NUOVA": "stato-segnalazione-nuova",
  "IN CARICO": "stato-segnalazione-in_carico",
  "SOSPESA": "stato-segnalazione-sospesa",
  "IN LAVORAZIONE": "stato-segnalazione-in_lavorazione",
  "PIANIFICATA": "stato-segnalazione-in_lavorazione",
  "COMPLETATA": "stato-segnalazione-completata",
  "RIFIUTATA": "stato-segnalazione-rifiutata"
};

export const  STILI_PRIORITA_SEGNALAZIONE = {
 "BASSA":     "priorita-segnalazione-bassa",
  "MEDIA":    "priorita-segnalazione-media",
  "ALTA":     "priorita-segnalazione-alta",
  "CRITICA":  "priorita-segnalazione-critica",
};


export const  STILI_STATO_TICKET = {
  "CREATO": "stato-ticket-creato",
  "IN CARICO": "stato-ticket-in_carico",
  "IN ATTESA": "stato-ticket-in_attesa",
  "PIANIFICATO": "stato-ticket-pianificato",
  "RISOLTO": "stato-ticket-risolto",
  "RISOLTO - STRAORDINARIA": "stato-ticket-risolto_extra"
};

export const  STILI_STATO_INTERVENTI = {
  "DA PIANIFICARE": "stato-intervento-creato",
  "IN ATTESA": "stato-intervento-in_attesa",
  "PIANIFICATO": "stato-intervento-pianificato",
  "ESEGUITO": "stato-intervento-eseguito",
  "NON ESEGUITO": "stato-intervento-non_eseguito"
};



export const  STILI_TIPO_SEGNALAZIONI = {
  "RICHIESTA / MODIFICA ORARIO": "tipo-segnalazione-orario",
  "SEGNALAZIONE": "tipo-segnalazione-segnalazione",
  "ALTRO": "tipo-segnalazione-altro",
  "LAMPADA SPENTA": "tipo-segnalazione-lampada_spenta",
  "LAMPADA DANNEGGIATA": "tipo-segnalazione-danneggiata",
  "STRADA SPENTA": "tipo-segnalazione-strada_spenta",
  "PALO DANNEGGIATO": "tipo-segnalazione-palo_danneggiato",
  "PORTELLA MANCANTE/DANNEGGIATA": "tipo-segnalazione-portella",
  "ORARI ACCENSIONE": "tipo-segnalazione-orari",
  "SEMAFORO": "tipo-segnalazione-semaforo",
  "IDRAULICA": "tipo-segnalazione-idraulica",
  "VOTIVA": "tipo-segnalazione-votiva",
};

export const  STILI_AREA_COMPETENZA_SEGNALAZIONI = {
  "CONDIZIONAMENTO - RISCALDAMENTO": "area-competenza-segnalazione-condizionamento",
  "PUBBLICA": "area-competenza-segnalazione-pubblica",
  "VOTIVA": "area-competenza-segnalazione-votiva",
  "IDRAULICA": "area-competenza-segnalazione-idraulica",
  "CALORE": "area-competenza-segnalazione-calore",
  "SEMAFORO": "area-competenza-segnalazione-semaforo",
  "ALTRO": "area-competenza-segnalazione-altro",
};

export const  STILI_TIPO_INTERVENTI= {
  "MODIFICA ORARIO": "tipo-intervento-orario",
  "SOSTITUZIONE LAMPADA": "tipo-intervento-sostituzione_lampada",
  "INTERVENTO SU LAMPADA": "tipo-intervento-intervento_lampada",
  "SOSTITUZIONE PALO": "tipo-intervento-sostituzione_palo",
  "INTERVENTO SU PALO": "tipo-intervento-intervento_palo",
  "SOSTITUZIONE PORTELLA": "tipo-intervento-sostituzione_portella",
  "INTERVENTO SU PORTELLA": "tipo-intervento-intervento_portella",
  "INTERVENTO SU SEMAFORO": "tipo-intervento-intervento_semaforo",
  "INTERVENTO SU LAMPADA VOTIVA": "tipo-intervento-intervento_lampada_votiva",
  "MODIFICA ORARI ACCENSIONE": "tipo-intervento-orari_accensione",
  //"RIPRISTINO ALIMENTAZIONE QUADRO": "tipo-intervento-qe",
  "ALTRO": "tipo-intervento-altro",
};

// STILI E IMPOSTAZIONI MAPPA

export const STATI_NASCOSTI_DASH_SEGNALAZIONI = [
  "COMPLETATA", "RIFIUTATA", "RISOLTA - STRAORDINARIA", "RISOLTA"
];

export const STATI_NASCOSTI_DASH_INTERVENTI = [
  "ESEGUITO", "NON ESEGUITO"
];


export const PRIORITY_STATO_SEGNALAZIONI = [
    "NUOVA", "IN CARICO", "SOSPESA", "IN LAVORAZIONE", "PIANIFICATA", "RIFIUTATA", "COMPLETATA", "RISOLTA", "RISOLTA - STRAORDINARIA"
  ];

export const STILIMAPPA_SEGNALAZIONI_STATO = {
    "NUOVA": {
      "fill": "#25894d",
      "text": "#ffffff",
      "icon": "/assets/icons/segnalazioni_nuove.png"
    },
    "IN CARICO": {
      "fill": "#A2E8DD",
      "text": "#1C56AC",
      "icon": "/assets/icons/segnalazioni_in_carico.png"
    },
    "IN LAVORAZIONE": {
      "fill": "#E6F8B2",
      "text": "#053839",
      "icon": "/assets/icons/segnalazioni_pianificate.png"
    },
    "PIANIFICATA": {
      "fill": "#E6F8B2",
      "text": "#053839",
      "icon": "/assets/icons/segnalazioni_pianificate.png"
    },
    "SOSPESA": {
      "fill": "#d7a84a",
      "text": "#ffffff",
      "icon": "/assets/icons/segnalazioni_sospese.png"
    },
    "COMPLETATA": {
      "fill": "#24ab55",
      "text": "#ffffff",
      "icon": "/assets/icons/segnalazioni_completate.png"
    },
    "RIFIUTATA": {
      "fill": "#e46917",
      "text": "#ffffff",
      "icon": "/assets/icons/segnalazioni_rifiutate.png"
    },
    "RISOLTA": {
      "fill": "#24ab55",
      "text": "#ffffff",
      "icon": "/assets/icons/segnalazioni_completate.png"
    },
    "RISOLTA - STRAORDINARIA": {
      "fill": "#24ab55",
      "text": "#ffffff",
      "icon": "/assets/icons/segnalazioni_completate.png"
    }
  };

export const PRIORITY_INTERVENTI_STATO = [
    "CREATO", "DA PIANIFICARE", "PIANIFICATO", "IN ATTESA", "ESEGUITO", "NON ESEGUITO"
  ];

export const STILIMAPPA_INTERVENTI_STATO = {
    "CREATO": {
      "icon": "/assets/icons/interventi_eseguiti-2.png",
      "fill": "#C4E4F5",
      "text": "#25894d",
      "render": true
    },
    "DA PIANIFICARE": {
      "icon": "/assets/icons/interventi_eseguiti-2.png",
      "fill": "#C4E4F5",
      "text": "#25894d",
      "render": true
    },
    "PIANIFICATO": {
      "icon": "/assets/icons/interventi_eseguiti-3.png",
      "fill": "#A2E8DD",
      "text": "#1C56AC",
      "render": true
    },
    "IN ATTESA": {
      "icon": "/assets/icons/interventi_eseguiti-1.png",
      "fill": "#1d0c56",
      "text": "#cbd7df",
      "render": true
    },
    "ESEGUITO": {
      "icon": "/assets/icons/interventi_eseguiti.png",
      "fill": "#24ab55",
      "text": "#ffffff",
      "render": true
    },
    "NON ESEGUITO": {
      "icon": "/assets/icons/interventi_non_eseguiti.png",
      "fill": "#e46917",
      "text": "#ffffff",
      "render": true
    }


  };

