import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";

import {DOCUMENT} from "@angular/common";
import {AuthService} from "@auth0/auth0-angular";
import {NGXLogger} from "ngx-logger";
import {UserService} from "src/app/services/user.service";
import {TabViewChangeEvent} from "primeng/tabview";
import {Segnalazione_Firestore, SegnalazioneStato} from "../../models/firestore/segnalazioneFirestore.model";
import {environment} from "../../../environments/environment";
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/compat/firestore";
import {ActivitiesMapWidgetComponent} from "../../components/activities-map-widget/activities-map-widget.component";
import { STILI_STATO_SEGNALAZIONI, STILI_AREA_COMPETENZA_SEGNALAZIONI, STILI_TIPO_SEGNALAZIONI, STILI_STATO_TICKET, STILI_TIPO_INTERVENTI, STILI_STATO_INTERVENTI } from '../../shared/stili';
import { Router } from "@angular/router";
import { UtentiService } from "src/app/services/utenti.service";
import { Timestamp, onSnapshot } from "firebase/firestore";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import { Ticket_Firestore, TicketStato } from "src/app/models/firestore/ticketFirestore.model";
import { Intervento_Firestore, InterventoStato } from "src/app/models/firestore/interventoFirestore.model";
import {ComuniService} from "../../services/comuni.service";

@Component({
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class HomeComponent implements OnInit, AfterViewInit {
  protected ruolo: string = "";
  protected isLoading: boolean = true;
  protected isLoggedIn: boolean = true;
  public isReady: boolean = false;

  protected isAdmin: boolean = false;
  protected isTecnico: boolean = false;
  protected isOperatore: boolean = false;
  protected isSegnalatore: boolean = false;


  lottieConfig: object;
  lottieConfigTBD: object;
  private anim: any;

  ref: DynamicDialogRef;



  ticket_totals_new: number;
  ticket_totals_accepted: number;
  ticket_totals_wip: number;
  ticket_totals_waiting: number;
  ticket_totals_done: number;
  ticket_totals_done_extra: number;

  interventi_totals_planned: number = 0;
  interventi_totals_new: number= 0;
  interventi_totals_waiting: number= 0;
  interventi_totals_not_done: number= 0;
  interventi_totals_done: number= 0;




  protected ultimiTicket:Ticket_Firestore[]=[];

  protected ultimiInterventi:Intervento_Firestore[]=[];


  protected stiliStatoSegnalazioni          = STILI_STATO_SEGNALAZIONI;
  protected stiliAreaCompetenzaSegnalazioni = STILI_AREA_COMPETENZA_SEGNALAZIONI;
  protected stiliTipoSegnalazioni           = STILI_TIPO_SEGNALAZIONI;
  protected stiliStatoTicket                = STILI_STATO_TICKET;
  protected stiliTipoInterventi             = STILI_TIPO_INTERVENTI;
  protected stiliStatoInterventi             = STILI_STATO_INTERVENTI;


  constructor(
    public auth: AuthService,
    private logger: NGXLogger,
    private userService: UserService,
    private firestore: AngularFirestore,
    private router: Router,
    private utentiService:UtentiService,
    public dialogService: DialogService,
    private messageService:MessageService,
    private storageService:GoogleStorageService,
    private comuniService: ComuniService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.lottieConfig = {
      path: '/assets/animations/ates-home.json',
      autoplay: true,
      loop: true,
      renderer: 'canvas',
    };
    this.lottieConfigTBD = {
      path: '/assets/animations/ates-home.json',
      autoplay: true,
      loop: true,
      renderer: 'canvas',
    };

  }

  ngOnInit() {

    let loadedUser = false;
    let loadedComuni = false;
    const checkAllLoaded = () => {
      if (loadedComuni && loadedUser) {
        this.isLoading = false;
      }
    }

    this.auth.user$.subscribe((user) => {
      if (user != null) {
        this.userService.loadSetUserInformation(user).then(() => {

          this.isAdmin = this.userService.isAdmin;
          this.isOperatore = this.userService.isOperatore;
          this.isTecnico = this.userService.isTecnico;
          this.isSegnalatore = this.userService.isSegnalatore;

          loadedUser = true;
          this.isLoggedIn = true;

          checkAllLoaded();

        });
      }
    });

    this.comuniService.getComuniMappa().subscribe((data) => {
      console.log("preloaded territories", data);
      console.log("entry 1", JSON.stringify(data[0]));
      loadedComuni = true;
      checkAllLoaded();
    })

  }


  ngAfterViewInit() {
  }


  loginWithRedirect() {
    this.auth.loginWithRedirect().subscribe((res) => {
    });
  }

  animationCreated(animation: any): void {
    this.anim = animation;
  }

  onTabChange(event: TabViewChangeEvent) {
    const selectedTabId = event.index;
    if(!this.userService.isSegnalatore && !this.userService.isTecnico){
      if(selectedTabId==0){

      }else if(selectedTabId==1){
        //this.loadInterventiData();
        //this.loadTicketData();
      }else if(selectedTabId==2){

      }
    }


  }




  //INTERVENTI
  /* loadInterventiData () {
    this.logger.info("** loadInterventiData ***");
    const collectionRef = this.firestore.collection(environment.firebase.collection_interventi).ref;
    let queryInterventi = collectionRef.orderBy('modificato', 'desc').limit(5);

    onSnapshot(queryInterventi, (qs) => {
      this.ultimiInterventi = qs.docs.map((item) => {
        this.logger.info(item.data());

        return item.data() as Intervento_Firestore;
      });
      //this.onDataChange();
      this.loadInterventiStats();

    });
  } */

  loadInterventiStats () {
    const collectionRef = this.firestore.collection(environment.firebase.collection_interventi).ref;
      let queryInterventi = collectionRef;

      onSnapshot(queryInterventi, (qs) => {
        let interventiPerStats:Intervento_Firestore[]  = qs.docs.map((item) => {

          return item.data() as Intervento_Firestore;
        });
        this.calcolaInterventiStats(interventiPerStats);
      });
  }

  calcolaInterventiStats(interventiPerStats:Intervento_Firestore[]){
    // console.log("* calcolaStats", segnalazioni.length);

    interventiPerStats.forEach((item) => {
       switch (item.stato) {
         case InterventoStato.CREATED:
           this.interventi_totals_new++;
           break;
         case InterventoStato.NOT_DONE:
           this.interventi_totals_not_done++;
           break;
         case InterventoStato.DONE:
           this.interventi_totals_done++;
           break;
         case InterventoStato.PLANNED:
           this.interventi_totals_planned++;
           break;
         case InterventoStato.SUSPENDED:
           this.interventi_totals_waiting++;
           break;
       }
     });
     this.isReady = true;
   }


  //TICKET - DA SPOSTARE IN COMPONENTE



}
