

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { MessageService } from "primeng/api";


import {
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { TipoSegnalazione } from "src/app/models/tipoSegnalazione.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { TipiSegnalazioneService } from "src/app/services/tipiSegnalazione.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  selector: "modifica-tipo_segnalazione",
  templateUrl: "./dialog-modifica-tipo_segnalazione.component.html",
  styleUrls: ["../tipi_segnalazione.component.css"],
})
export class ModificaTipoSegnalazioneComponent implements OnInit {
  protected _onDestroy = new Subject<void>();

  protected modificaTipoSegnalazioneForm: FormGroup;
  protected areeCompetenza:AreaCompetenza[]=[];
  protected selectedTipoSegnalazione:TipoSegnalazione;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private utilService:UtilService,
    private areaCompetenzaService:AreeCompetenzaService,
    private tipiSegnalazioneService:TipiSegnalazioneService,
  ) {
    this.selectedTipoSegnalazione = this.config.data.tipoSegnalazione;
  }

  ngOnInit(): void {
    this.loadAreeCompetenza();
    this.modificaTipoSegnalazioneForm = this._formBuilder.group({
      areaCompetenza :    [this.selectedTipoSegnalazione.areaCompetenza, [Validators.required]],
      label:              [this.selectedTipoSegnalazione.label, [Validators.required]],
      colore:             [this.selectedTipoSegnalazione.colore, [Validators.required]],
      giorniRisoluzione:  [this.selectedTipoSegnalazione.giorniRisoluzione, [Validators.required]]

    });
  }

  loadAreeCompetenza(){
    this.areaCompetenzaService.get().subscribe(areeCompetenzaFromDB =>{
      this.areeCompetenza = areeCompetenzaFromDB;
    });
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let tipoSegnalazioneDaModificare: TipoSegnalazione = new TipoSegnalazione();
          tipoSegnalazioneDaModificare.id                   = this.selectedTipoSegnalazione.id;
          tipoSegnalazioneDaModificare.label                = this.modificaTipoSegnalazioneForm.value.label;
          tipoSegnalazioneDaModificare.colore                = this.modificaTipoSegnalazioneForm.value.colore;
          tipoSegnalazioneDaModificare.areaCompetenza       = this.modificaTipoSegnalazioneForm.value.areaCompetenza;
          tipoSegnalazioneDaModificare.giorniRisoluzione    = this.modificaTipoSegnalazioneForm.value.giorniRisoluzione;

       this.tipiSegnalazioneService.modifica(tipoSegnalazioneDaModificare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE CREAZIONE TIPO INTERVENTO" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di creazione del tipo di intervento!", life:10000
          });
        }
      );
    }
  }
}
