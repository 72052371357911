import {Component, OnInit} from '@angular/core';
import {NGXLogger} from "ngx-logger";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {MessageService, PrimeTemplate} from "primeng/api";
import {FormBuilder, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UtilService} from "../../../../services/util.service";
import {AreeCompetenzaService} from "../../../../services/areeCompetenza.service";
import {DropdownModule} from "primeng/dropdown";
import {UpperCasePipe} from "@angular/common";
import {ComuneCreationDescriptor, ComuneIstat, ProvinciaCatasto, RegioneIstat} from "../../../../models/territori_api";
import {ComuneDTO} from "../../../../models/comune.model";
import {ButtonDirective} from "primeng/button";
import {Ripple} from "primeng/ripple";
import {ComuniService} from "../../../../services/comuni.service";
import { AreaCompetenza } from 'src/app/models/areaCompetenza.model';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  selector: 'app-aggiunta-comune-dialog',
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    PrimeTemplate,
    ReactiveFormsModule,
    UpperCasePipe,
    ButtonDirective,
    MultiSelectModule,
    Ripple
  ],
  templateUrl: './aggiunta-comune-dialog.component.html',
  styleUrl: './aggiunta-comune-dialog.component.scss'
})
export class AggiuntaComuneDialogComponent implements OnInit {

  codiciCensiti: string[];

  optionsRegioni: RegioneIstat[] = [];
  optionsProvince: ProvinciaCatasto[] = [];
  optionsComuni: ComuneSelectItem[] = [];

  optionsAreeCompetenza: AreaCompetenza[] = [];

  selectedRegione: RegioneIstat;
  selectedProvincia: ProvinciaCatasto;
  selectedComune: ComuneIstat;
  selectedAreeCompetenza: number[] = [];

  saving: boolean = false;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private utilService: UtilService,
    private areeCompetenzaService: AreeCompetenzaService,
    private comuniService: ComuniService,

  ) {
    // console.log("config", config.data);
    this.codiciCensiti = (config.data?.["censiti"] ?? [] as ComuneDTO[]).map((comune: ComuneDTO) => {
      return comune.codiceIstat;
    });
    this.refreshRegioni();
  }

  ngOnInit(): void {
  }

  refreshRegioni () {
    this.utilService.getRegioni().subscribe((regioni: RegioneIstat[]) => {
      // console.log("regioni", regioni);
      this.optionsRegioni = [...regioni];
    })
  }

  onSelectRegione (selected: RegioneIstat) {
    // console.log("selected regione ", selected);
    if (selected != this.selectedRegione) {
      this.selectedProvincia = null;
      this.optionsProvince = [];
      this.selectedComune = null;
      this.optionsComuni = [];
    }
    this.selectedRegione = selected;
    this.refreshProvince();
  }

  refreshProvince () {
    this.utilService.getProvinceRegione(this.selectedRegione.codiceISTAT).subscribe((province) => {
      // console.log("province", province);
      this.optionsProvince = [...province];
    });
  }

  onSelectProvincia (selected: ProvinciaCatasto) {
    if (selected != this.selectedProvincia) {
      // console.log("selected provincia", selected);
      this.selectedComune = null;
      this.optionsComuni = [];
    }
    this.selectedProvincia = selected;
    this.refreshComuni();
  }

  refreshComuni () {
    this.utilService.getComuniProvincia(this.selectedProvincia.idCatastale).subscribe((comuni) => {
      // console.log("comuni per ", this.selectedProvincia, comuni);
      this.optionsComuni = [...comuni.map((item) => {
        return {...item,
          censito: this.codiciCensiti.indexOf(item.codiceISTAT) != -1
        };
      })];
    });
  }

  onSelectComune (selected: ComuneIstat) {
    // console.log("selected comune ", selected);
    this.selectedComune = selected;
    this.refreshAreeCompetenza();
  }


  refreshAreeCompetenza(){
    this.areeCompetenzaService.get().subscribe((areeCompetenza) => {
      this.optionsAreeCompetenza = areeCompetenza;
    });
  }

  conferma (confirm: boolean) {
    if (confirm) {
      this.saving = true;
       let areeCompetenzaSelezionate:AreaCompetenza[]=[];
       if(this.selectedAreeCompetenza.length > 0){
        this.selectedAreeCompetenza.forEach(areaCompetenzaSelezionata => {
          const ac:AreaCompetenza = new AreaCompetenza();
          ac.id = areaCompetenzaSelezionata ;
          areeCompetenzaSelezionate.push(ac);
        });
       }
      const forAPI: ComuneCreationDescriptor = {
        codiceIstat: this.selectedComune.codiceISTAT,
        denominazione: this.selectedComune.comune,
        regione: {
          id: this.selectedRegione.id
        },
        provincia: this.selectedProvincia.denominazione,
        provinciaSigla: this.selectedProvincia.sigla,
        areeCompetenza:areeCompetenzaSelezionate
      }

      console.log(forAPI );
       this.comuniService.aggiungiComune(forAPI).subscribe((result) => {

        this.saving = false;
        this.dialogRef.close(forAPI);
      });

    } else {
      this.dialogRef.close(null);
    }
  }
}

interface ComuneSelectItem extends ComuneIstat {
  censito: boolean;
}
