<form [formGroup]="creaTipoInterventoForm">
    <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12">
            <label for="label" class="font-medium text-900">AREA DI COMPETENZA</label>
              <span class="p-input-icon-left">
                <i class="pi pi-warehouse"></i>
                <p-dropdown inputId="areaCompetenza" [options]="areeCompetenza" optionLabel="label" [showClear]="true"
                      placeholder="Scegli l'area di competenza" formControlName="areaCompetenza">
                      <ng-template let-areaCompetenza pTemplate="item">
                          <div class="flex align-items-center">
                              <div>{{areaCompetenza.label | uppercase}}</div>
                          </div>
                      </ng-template>
                  </p-dropdown>
              </span>
        </div>
        <div class="field mb-4 col-12">
          <label for="label" class="font-medium text-900">IDENTIFICATIVO</label>
          <span class="p-input-icon-left">
              <i class="pi pi-warehouse"></i>
            <input id="label" type="text" type="label" formControlName="label" pInputText>
          </span>
      </div>
      <div class="field mb-4 col-12">
        <label for="colore" class="font-medium text-900 mr-4">COLORE</label>
        <p-colorPicker id="colore" formControlName="colore" />
      </div>
    </div>
</form>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!creaTipoInterventoForm.valid"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button>
</div>
