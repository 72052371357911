<div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <span *ngIf="!filterIsSet" class="text-xl font-medium text-2000 align-items-center mr-4" style="color: rgb(10, 57, 29)">SEGNALAZIONI</span>
      <span *ngIf="filterIsSet && filtroStato" class="text-xl font-medium text-2000 align-items-center mr-4" style="color: rgb(48, 100, 197)">SEGNALAZIONI IN STATO:<span style="font-weight: bold;"> {{filtroStato}}</span> </span>
      <span *ngIf="filterIsSet && filtroSegnalazione" class="text-xl font-medium text-2000 align-items-center mr-4" style="color: rgb(106, 187, 25)">SEGNALAZIONE <span style="font-weight: bold;">{{filtroSegnalazione}}</span></span>

    </div>

  <div class="flex justify-end md:justify-end">

      <span style="margin-right: 1em">
        <p-button icon="pi pi-download" class="p-button-text mr-1" (click)="downloadAsExcel()"></p-button>
      </span>

      <p-multiSelect class="fieldFilterControl" *ngIf="!filterIsSet"
        [showHeader]="false"
        [filter]="false"
        [options]="opzioniAreeCompetenza"
        display="chip"
        optionLabel="label"
        optionValue="value"
        [(ngModel)]="filtroSegnalazioni.area"
        (onChange)="applyFilters()"
        placeholder="Area di competenza"
        [maxSelectedLabels]="1"
      />


      <p-multiSelect class="fieldFilterControl" *ngIf="!filterIsSet"
        [showHeader]="false"
        [filter]="false"
        [options]="opzioniTipoArea"
        [group]="true"
        display="chip"
        [(ngModel)]="filtroSegnalazioni.tipo"
        (onChange)="applyFilters()"
        placeholder="Tipo"
        [maxSelectedLabels]="1"
      />

      <p-multiSelect class="fieldFilterControl" *ngIf="!filterIsSet"
        [showHeader]="false"
        [filter]="false"
        [options]="opzioniStati"
        display="chip"
        optionLabel="label"
        optionValue="value"
        [(ngModel)]="filtroSegnalazioni.stato"
        (onChange)="applyFilters()"
        placeholder="Stato"
        [maxSelectedLabels]="1"
      />

      <p-multiSelect class="fieldFilterControl" *ngIf="!filterIsSet"
        [showHeader]="false"
        [filter]="false"
        [options]="opzioniPriority"
        display="chip"
        optionLabel="label"
        optionValue="value"
        [(ngModel)]="filtroSegnalazioni.priority"
        (onChange)="applyFilters()"
        placeholder="Priorità"
        [maxSelectedLabels]="1"
      />

      <p-multiSelect class="fieldFilterControl" *ngIf="!filterIsSet"
        display="chip"
        [options]="opzioniComuni"
        [showToggleAll]="false"
        optionLabel="label"
        optionValue="value"
        [(ngModel)]="filtroSegnalazioni.comune"
        (onChange)="applyFilters()"
        placeholder="Comune"
        [maxSelectedLabels]="1"
      />


    <button *ngIf="filterIsSet" pButton pRipple type="button" icon="pi pi-filter-slash"
          class="py-2 px-3 md:mb-0 mr-0 md:mr-3 p-button-rounded p-button-outlined" style="background-color: rgb(73, 162, 173); color: white;"
          (click)="reloadDataRemovingFilter()" [disabled]="isLoadingResults"
          pTooltip="Rimuovi filtro"></button>
    <span class="p-input-icon-left mr-4" *ngIf="!filterIsSet ">
      <i class="pi pi-search" *ngIf="!filterIsSet "></i>
      <input type="text" pInputText placeholder="Cerca" style="border-radius: 20px;"
        (input)="filtraSegnalazioni($event)" [disabled]="isLoadingResults" *ngIf="!filterIsSet "/>
      </span>

      <button *ngIf="isAdmin || isOperatore "
      pButton type="button" icon="pi pi-plus"
      pTooltip="Nuova Segnalazione"
      class="inline-flex justify-content-center align-items-center mb-1 mr-1"
      style="background-color:#CBEFFF ; color: #007bff; border-color: #CBEFFF; font-family:'Poppins'"
      tooltipPosition="top"
      label="Nuova Segnalazione"
        (click)="openCreaSegnalazioneDialog()" [disabled]="isLoadingResults">
      </button>
     <!--  <button *ngIf="isAdmin || isSuperAdmin || isSuperVisore || isTecnico" pButton type="button" icon="pi pi-map" class="p-button-rounded p-button-outlined mr-2 ml-2" style="background-color: rgb(9, 175, 197); color: white;"  tooltipPosition="top" pTooltip="Nuova richiesta da Mappa"
      (click)="openCreaSegnalazioneDaMappaDialog()" [disabled]="isLoadingResults">
    </button> -->
    </div>
  </div>

  <p-tabView>
    <p-tabPanel>
      <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2">
              <span class="font-bold white-space-nowrap m-0" style="color: #5EB1FC;">
                Elenco
              </span>
          </div>
      </ng-template>
        <div fxFlex="95%">

              <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>
             <!-- [globalFilterFields]="['id','idRichiesta','creata']"
                 -->
              <p-table #segnalazioniDT styleClass="p-datatable-sm" [value]="segnalazioniFiltrate" dataKey="id" [(selection)]="selectedSegnalazione"
                [selectionPageOnly]="true" [rowHover]="true" [rows]="30" [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[30,50,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
                [globalFilterFields]="['id','codice','comune','segnalatore','descrizione']"
                [sortField]="'modificata'" [sortOrder]="-1"
                >
                <ng-template pTemplate="header">
                  <tr>
                    <th style="font-size: 11px;">CODICE</th>
                    <!-- <th >REGIONE</th> -->
                    <th style="font-size: 11px;">PROVINCIA</th>
                    <th pSortableColumn="comune" style="font-size: 11px;">
                      COMUNE
                      <p-sortIcon field="comune"></p-sortIcon>
                    </th>
                    <th pSortableColumn="segnalatore" style="font-size: 11px;">
                      AUTORE
                      <p-sortIcon field="segnalatore"></p-sortIcon>
                    </th>
                    <th *ngIf="isAdmin || isOperatore" style="font-size: 11px;">OPERATORE</th>
                    <th pSortableColumn="creata" style="font-size: 11px;">
                      INSERITA
                      <p-sortIcon field="creata"></p-sortIcon>
                    </th>
                    <th pSortableColumn="modificata" style="font-size: 11px;">
                      MODIFICATA
                      <p-sortIcon field="modificata"></p-sortIcon>
                    </th>
                    <th style="font-size: 11px;">DESCRIZIONE</th>
                    <th pSortableColumn="scadenza" class="text-center" style="font-size: 11px;">
                      SCADENZA
                      <p-sortIcon field="scadenza"></p-sortIcon>
                    </th>
                    <th pSortableColumn="priorita" class="text-center" style="font-size: 11px;">
                      PRIORITÀ
                      <p-sortIcon field="priorita"></p-sortIcon>
                    </th>
                    <th pSortableColumn="areaDiCompetenza" class="text-center" style="font-size: 11px;">
                      AREA
                      <p-sortIcon field="areaDiCompetenza"></p-sortIcon>
                    </th>
                    <th pSortableColumn="tipo" class="text-center" style="font-size: 11px;">
                      TIPO
                      <p-sortIcon field="tipo"></p-sortIcon>
                    </th>


                    <th  class="text-center" style="font-size: 11px;" class="text-center">
                      STATO

                    </th>
                    <!-- <th class="text-center" style="width: 8px;"></th> -->
                    <th class="text-center" style="width: 8px;"></th>
                    <th class="text-center" style="width: 8px;"></th>
                    <th style="width: 8px;"></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row>
                  <tr>


                    <td> <span class="text-uppercase" style="font-size: 11px;font-weight: 500; font-weight: bold;"  >{{row.label?row.label:row.codice | uppercase}}</span></td>
                    <!-- <td> <span class="text-uppercase" >{{row.regione | uppercase}}</span></td> -->
                    <td> <span class="text-uppercase" style="font-size: 11px;">{{row.provincia | uppercase}}</span></td>
                    <td> <span class="text-uppercase" style="font-size: 11px;">{{row.comune | uppercase}}</span></td>
                    <td> <span class="text-uppercase" style="font-size: 11px;">{{row.nomeAutore | uppercase}}</span></td>
                    <td *ngIf="isAdmin || isOperatore "> <span class="text-uppercase" style="font-size: 11px;">{{row.nomeOperatoreInCarico | uppercase}}</span></td>
                    <td class="text-left"> <span class="text-lowercase" style="font-size: 11px;">{{formattaData(row.creata)}}</span></td>
                    <td class="text-left"> <span class="text-lowercase" style="font-size: 12px;">{{formattaData(row.modificata)}}</span></td>
                    <td class="text-left"> <span  style="font-size: 10px; font-weight: bold;">{{row.descrizione | uppercase}}</span></td>
                    <td class="text-left"> <span class="text-lowercase" style="font-size: 12px;">
                      {{formattaData(row["scadenza"])}}
                    </span></td>
                    <td class="text-center">
                      <span *ngIf="row.priorita" [ngClass]="stiliPrioritaSegnalazioni[row.priorita]" >{{ row.priorita | uppercase}}</span>
                    </td>
                    <td class="text-center">
                      <span
                      class="material-symbols-outlined small-icon"
                      [ngClass]="getAreaCompetenzaColor(row.areaDiCompetenza)"
                      [ngStyle]="{ 'color': getAreaCompetenzaColor(row.areaDiCompetenza) }"
                      [pTooltip]="row.areaDiCompetenza" tooltipPosition="top">
                      {{ stiliService.getMaterialIconAreaCompetenza(row.areaDiCompetenza) }}
                    </span>
                      <!-- <span  [ngClass]="stiliAreaCompetenzaSegnalazioni[row.areaDiCompetenza]"  style="font-size:9px;">{{row.areaDiCompetenza | uppercase}}</span> -->

                    </td>
                    <td class="text-center">
                      <span  class="tipo-segnalazione" [style]="{ 'background-color': getTipoSegnalazioneColor(row.tipo) }" style="font-size: 10px;">
                        {{row.tipo | uppercase}}
                      </span>
                      </td>
                    <td class="text-center">
                      <span
                        class="material-symbols-outlined small-icon"
                        [ngClass]="stiliService.getMaterialIconStatoSegnalazione(row.stato)"
                        [ngStyle]="{ 'color': stiliService.getColoreIconaStatoSegnalazione(row.stato) }"
                        [pTooltip]="getTooltipContent(row)" tooltipPosition="top">
                        {{ stiliService.getMaterialIconStatoSegnalazione(row.stato) }}
                      </span>
                      <!-- <span [ngClass]="stiliStatoSegnalazioni[row.stato]" style="font-size: 10px;">{{row.stato | uppercase}}</span> -->
                      </td>
                     <!--  <td class="text-center">
                        <i *ngIf="row.stato==='PIANIFICATA'" class="pi pi-calendar-clock" style="color: #07582a; cursor: pointer" pTooltip="Vedi pianificazioni"
                          tooltipPosition="top" (click)="apriInterventi(row)">
                        </i>
                      </td> -->
                      <td class="text-center">
                        <i *ngIf="row.allegato"  class="material-symbols-outlined small-icon" style="color: #033d63; cursor: pointer"
                        pTooltip="Mostra Allegati"
                          tooltipPosition="top" (click)="mostraAllegatiSegnalazione(row)">
                          attach_file
                        </i>
                      </td>
                      <td class="text-center">
                        <i *ngIf="isRisolta(row)"  class="material-symbols-outlined small-icon" style="color: #033d63; cursor: pointer"
                        pTooltip="Mostra note"
                          tooltipPosition="top" (click)="mostraNoteSegnalazione(row)">
                          edit_note
                        </i>
                        <i *ngIf="isRifiutata(row)"  class="material-symbols-outlined small-icon" style="color: #611f22; cursor: pointer"
                        pTooltip="Mostra note"
                          tooltipPosition="top" (click)="mostraNoteRifiuto(row)">
                          edit_note
                        </i>
                      </td>
                      <td>
                        <button *ngIf=" showPulsanteAzioni(row)"
                          pButton type="button" style="color:rgb(39, 128, 15)"
                          icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text"
                          (click)="openCaricaAzioni($event,row)">
                        </button>
                      </td>
                  </tr>
                </ng-template>
              </p-table>
              <p-tieredMenu #menuAzioniTiered [popup]="true" [model]="menuAzioniItems"></p-tieredMenu>
            </div>

    </p-tabPanel>
    <p-tabPanel >
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2">
            <span class="font-bold white-space-nowrap m-0" style="color: #5EB1FC;">
              Mappa
            </span>
        </div>
    </ng-template>

        <div fxFlex="95%" style="height: 68vh">
          <app-activities-map-widget [interventi]="[]" [segnalazioni]="segnalazioniFiltrate"></app-activities-map-widget>
        </div>

    </p-tabPanel>
  </p-tabView>

</div>

<p-toast position="top-center"></p-toast>

<p-dialog [(visible)]="noteRisoluzioneVisibili" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
  <div class="flex flex-column align-items-center my-4">
      <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle mb-3" style="width:64px;height:64px">
          <i class="pi pi-clipboard text-5xl"></i>
      </span>
      <div class="font-medium text-2xl text-900">NOTE RISOLUZIONE</div>
  </div>
  <p class="line-height-3 p-0 m-0">
     {{noteRisoluzione}}
  </p>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3 flex-container">
      <button pButton pRipple icon="pi pi-times" (click)="noteRisoluzioneVisibili = false" label="CHIUDI" class="p-button-outlined w-6"></button>
  </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="noteRifiutoVisibili" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}" [closable]="false" [showHeader]="false">
  <div class="flex flex-column align-items-center my-4">
      <span class="flex align-items-center justify-content-center bg-red-100 text-cyan-800 mr-3 border-circle mb-3" style="width:64px;height:64px">
          <i class="pi pi-clipboard text-5xl"></i>
      </span>
      <div class="font-medium text-2xl text-900">NOTE RIFIUTO</div>
  </div>
  <p class="line-height-3 p-0 m-0">
     {{noteRifiuto}}
  </p>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3 flex-container">
      <button pButton pRipple icon="pi pi-times" (click)="noteRifiutoVisibili = false" label="CHIUDI" class="p-button-outlined w-6"></button>
  </div>
  </ng-template>
</p-dialog>
