import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, MessageService } from "primeng/api";
import { of, Subject, Observable } from "rxjs";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ComuneDTO } from "src/app/models/comune.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UserService } from "src/app/services/user.service";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";
import { NominatimResponse } from "src/app/models/nominatim/response.model";
import { TipoIntervento } from "src/app/models/tipoIntervento.model";
import { CalendarOptions } from "@fullcalendar/core";
import {  DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import italiano from '@fullcalendar/core/locales/it';
import { Segnalazione_Firestore } from "src/app/models/firestore/segnalazioneFirestore.model";
import { Intervento } from "src/app/models/intervento.model";
import { InterventiService } from "src/app/services/interventi.service";
import { StatoSegnalazione } from "src/app/models/stato.segnalazione.model";
import { EventiService } from "src/app/services/eventi.service";
import { Intervento_Firestore } from "src/app/models/firestore/interventoFirestore.model";
import { RapportiniService } from "src/app/services/rapportini.service";
import { Rapportino } from "src/app/models/rapportino.model";
import { Utente } from "src/app/models/utente.model";
import { forEach } from "jszip";
import { GoogleStorageService } from "src/app/services/googleStorage.service";

function oraInizioBeforeOraFineValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const form = control.parent as FormGroup;
    if (form) {
      const oraInizio = form.get('oraInizio')?.value;
      const oraFine = form.get('oraFine')?.value;

      if (oraInizio && oraFine && oraInizio > oraFine) {
        return { 'oraInizioAfterOraFine': true };
      }
    }
    return null;
  };
}

@Component({
  selector: "rapportino_intervento",
  templateUrl: './rapportino.component.html',
  styleUrls: ['../interventi.component.css'],
 // providers: [DialogService, ConfirmationService],
})
export class RapportinoComponent implements OnInit, OnDestroy {
  protected _onDestroy = new Subject<void>();
  protected ref: DynamicDialogRef;
  protected rapportinoForm: FormGroup;
  protected selectedInterventoFirestore:Intervento_Firestore;

  protected rapportino:Rapportino;
  protected useCreate:Boolean = false;
  protected isLoadingAccompagnatori:Boolean = false;
  protected isLoadingTecnici:Boolean = false;

  protected accompagnatori:Utente[] = [];
  protected tecnici:Utente[] = [];

  protected selectedFiles: File[] = [];
  protected isFileUploaded: boolean = false;
  protected showFileComponent: boolean = false;

  @HostBinding("class.mobile") isMobile: boolean;
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private _formBuilder: FormBuilder,
      public dialogService: DialogService,
      private messageService: MessageService,
      protected userService:UserService,
      protected tipiInterventoService:TipiInterventoService,
      private confirmationService: ConfirmationService,
      private rapportiniService:RapportiniService,
      private utentiService:UtentiService,
      private googleStorageService:GoogleStorageService
    ) {
      this.detectDevice();
      this.selectedInterventoFirestore = this.config.data.intervento;

  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnDestroy(): void {

  }


  ngOnInit(): void {
    this.logger.info("*** ngOnInit RAPPORTINO" );


    this.rapportinoForm = this._formBuilder.group({
      segnalazione:     new FormControl({value: this.selectedInterventoFirestore.codiceSegnalazione, disabled: true}, Validators.required),
      intervento:       new FormControl({value: this.selectedInterventoFirestore.codice, disabled: true}, Validators.required),
      tipoIntervento:   new FormControl({value: this.selectedInterventoFirestore.tipo, disabled: true}, Validators.required),
      esecutore:        new FormControl({value: this.selectedInterventoFirestore.tipo, disabled: true}, Validators.required),
      accompagnatore:   new FormControl({value: this.selectedInterventoFirestore.tipo, disabled: true}),
      allDay:   new FormControl({value: null, disabled: false}),
      dataIntervento:   new FormControl({value: null, disabled: true}, Validators.required),
      oraInizio:        new FormControl({value: null, disabled: true}, [Validators.required, oraInizioBeforeOraFineValidator()]),
      oraFine:          new FormControl({value: null, disabled: true}, [Validators.required,  ]),
      durata:           new FormControl({value: null, disabled: true}, [Validators.required,Validators.min(1)]),
      cestello:         new FormControl({value: null, disabled: true}),
      note:             new FormControl({value: null, disabled: true}),

    });

    this.rapportinoForm.get('oraInizio').valueChanges.subscribe(() => this.updateDurata());
    this.rapportinoForm.get('oraFine').valueChanges.subscribe(() => this.updateDurata());
    this.loadTecnici();

    this.loadRapportino();
    this.onTuttoGiornoChange();
  }
  formattaDurata(minuti: number): string {
    const ore = Math.floor(minuti / 60);
    const restantiMinuti = minuti % 60;
    let risultato = '';
    if (ore > 0) {
      risultato += `${ore}h `;
    }
    if (restantiMinuti > 0) {
      risultato += `${restantiMinuti}m`;
    }
    return risultato.trim();
  }

  loadTecnici(){
    this.isLoadingTecnici = true;
    this.utentiService.getTecnici().subscribe(tecniciFromDB=>{
      this.tecnici        = tecniciFromDB;
      this.accompagnatori = tecniciFromDB;
      this.isLoadingTecnici = false;
      this.rapportinoForm.get('esecutore')?.enable();
      this.accompagnatori = this.accompagnatori.filter(tecnico => tecnico.id != this.rapportinoForm.get('esecutore').value.id);
      this.rapportinoForm.get('accompagnatore')?.enable();

      //this.rapportinoForm.get('accompagnatore')?.enable();
    });
  }

  loadAccompagnatori(){
    this.isLoadingAccompagnatori = true;
    this.utentiService.getTecnici().subscribe(tecniciFromDB=>{
      this.accompagnatori = tecniciFromDB.filter(tecnico => tecnico.id != this.rapportinoForm).get('esecutore').value.id;
      this.isLoadingAccompagnatori = false;
    });
    this.rapportinoForm.get('accompagnatore')?.enable();

  }

  selezionaTecnico(event:any){
    this.accompagnatori = this.tecnici;
    this.accompagnatori = this.accompagnatori.filter(tecnico => tecnico.id != this.rapportinoForm.get('esecutore').value.id);
    this.rapportinoForm.get('accompagnatore')?.enable();
  }


  convertTimeStringToDate(timeString: string): Date {
    const date = new Date();

    if(timeString){
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
      date.setHours(hours, minutes, seconds, 0); // Imposta ore, minuti, secondi e millisecondi a 0

    }
    return date;
  }
  formatTime(date: Date): string {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String('00');
    return `${hours}:${minutes}:${seconds}`;
  }
  formatDate(date: Date):string{
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;

  }
   parseToLocalDate(dataOriginale: Date): Date {
    return new Date(dataOriginale.getFullYear(), dataOriginale.getMonth() +  - 1, dataOriginale.getDate());
  }

  loadRapportino(){
    const oraInizio = new Date();
    const oraFine = new Date();
    oraInizio.setHours(8, 0, 0, 0);
    oraFine.setHours(8, 30, 0, 0);
    this.rapportiniService.getByIDIntervento(this.selectedInterventoFirestore.id.toString()).subscribe({
      next: (rapportino) => {
        if(rapportino){
           this.rapportino = rapportino;
          const dataIntervento = new Date(rapportino.giornoEsecuzione);
          if(this.rapportino && this.rapportino.esecutore){
            this.tecnici.forEach(tecnico => {
              if(tecnico.id == this.rapportino.esecutore.id){
                this.rapportinoForm.get("esecutore").setValue(tecnico);
                this.rapportinoForm.get('esecutore')?.enable();
              }
            });

            this.accompagnatori = this.tecnici.filter(tecnico => tecnico.id != this.rapportino.esecutore.id);
          }
          if(this.rapportino.accompagnatore){
            this.accompagnatori.forEach(accompagnatore => {
              if(accompagnatore.id == this.rapportino.accompagnatore.id){

                this.rapportinoForm.get("accompagnatore").setValue(accompagnatore);
                this.rapportinoForm.get('accompagnatore')?.enable();
              }
            });

          }

          this.rapportinoForm.get("dataIntervento").setValue(dataIntervento);
          this.rapportinoForm.get("allDay").setValue(rapportino.allDay);
           if(!rapportino.allDay){
            this.rapportinoForm.get("oraInizio").setValue(rapportino.oraInizio?this.convertTimeStringToDate(rapportino.oraInizio):oraInizio);
            this.rapportinoForm.get("oraFine").setValue(rapportino.oraFine?this.convertTimeStringToDate(rapportino.oraFine):oraFine);
            this.rapportinoForm.get("durata").setValue(this.formattaDurata(rapportino.durata));
            this.rapportinoForm.get('oraInizio')?.enable();
            this.rapportinoForm.get('oraFine')?.enable();

          }else{
            this.rapportinoForm.get("durata").setValue(this.formattaDurata(rapportino.durata));
          }

          this.rapportinoForm.get("cestello").setValue(rapportino.cestello);

          this.rapportinoForm.get("note").setValue(rapportino.note);
        }else{
          this.useCreate = true;
          this.showFileComponent = true;

          this.rapportinoForm.get("oraInizio").setValue(oraInizio);
          this.rapportinoForm.get("oraFine").setValue(oraFine);

          this.rapportinoForm.get('oraInizio')?.enable();
          this.rapportinoForm.get('oraFine')?.enable();
        }

        this.rapportinoForm.get('dataIntervento')?.enable();
         this.rapportinoForm.get('cestello')?.enable();
        this.rapportinoForm.get('note')?.enable();

        this.logger.info(this.rapportinoForm.get("esecutore").value);

        // Gestisci i dati ricevuti
        //console.log('Dati intervento:', data);
      },
      error: (error) => {
        this.messageService.add({
          severity: "info",
          summary: "Errore" , life:10000,
          detail: "Errore in fase di caricamento del rapportino!",
        });
      },
      complete: () => {
        // Questo codice verrà eseguito una volta che l'Observable è completato
        this.loadTecnici();
      }
    });

  }

  onFileUpload($event) {
    this.selectedFiles = $event.files;
    if(this.selectedFiles.length > 0){
      this.isFileUploaded = true;
    }

  }
  onFileRemove() {
    if(this.selectedFiles.length == 0){
      this.isFileUploaded = false;
    }
  }

  onTuttoGiornoChange() {
    this.rapportinoForm.get('allDay')?.valueChanges.subscribe((value: boolean) => {

      if (value) { //tutto il giorno

        this.rapportinoForm.get('oraInizio')?.reset();
        this.rapportinoForm.get('oraFine')?.reset();

        this.rapportinoForm.get('oraInizio')?.disable();
        this.rapportinoForm.get('oraFine')?.disable();
        this.rapportinoForm.get('durata').setValue(this.formattaDurata(480), { emitEvent: false });
      } else { //scelta orario
        const oraInizio = new Date();
        const oraFine = new Date();
        oraInizio.setHours(oraInizio.getHours(), 0, 0, 0);
        oraFine.setHours(oraFine.getHours(), 30, 0, 0);

        this.rapportinoForm.get("oraInizio").setValue(oraInizio);
        this.rapportinoForm.get("oraFine").setValue(oraFine);

        this.rapportinoForm.get('oraInizio')?.enable();
        this.rapportinoForm.get('oraFine')?.enable();
        this.updateDurata();
      }
    });
  }

  updateDurata(): void {
    const oraInizio = this.rapportinoForm.get('oraInizio').value;
    const oraFine = this.rapportinoForm.get('oraFine').value;

    if (oraInizio && oraFine) {
      const durata = this.formattaDurata(this.calculateDuration(oraInizio, oraFine));
      this.rapportinoForm.get('durata').setValue(durata, { emitEvent: false }); // Imposta il valore senza emettere eventi
    }
  }

  calculateDuration(startTime: Date, endTime: Date): number {
    // Calcola la differenza in millisecondi
    const start = new Date(startTime);
    const end = new Date(endTime);
    const diffMs = end.getTime() - start.getTime();

    // Converti la differenza in minuti
    return Math.floor(diffMs / (1000 * 60));
  }


  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let rapportinoDaSalvare:Rapportino;
      if(this.useCreate){
        rapportinoDaSalvare = new Rapportino();
        let intervento:Intervento = new Intervento();
        intervento.id = this.selectedInterventoFirestore.id;
        rapportinoDaSalvare.intervento        = intervento
      }else{
        rapportinoDaSalvare = this.rapportino;
      }
      let redattore:Utente = new Utente();
      redattore.id = this.userService.getUser().id;
      rapportinoDaSalvare.redattore = redattore;

      let esecutore:Utente = new Utente();
          esecutore.id = this.rapportinoForm.get('esecutore').value.id;
      rapportinoDaSalvare.esecutore = esecutore;
      if(this.rapportinoForm.get('accompagnatore').value){
        let accompagnatore:Utente = new Utente();
        accompagnatore.id = this.rapportinoForm.get('accompagnatore').value.id;
        rapportinoDaSalvare.accompagnatore = accompagnatore;
      }

      rapportinoDaSalvare.giornoEsecuzione  = this.formatDate(this.rapportinoForm.get('dataIntervento').value);
      if(!this.rapportinoForm.get('allDay').value){
        rapportinoDaSalvare.oraInizio         = this.formatTime(this.rapportinoForm.get('oraInizio').value);
        rapportinoDaSalvare.oraFine           = this.formatTime(this.rapportinoForm.get('oraFine').value);
        rapportinoDaSalvare.allDay           = false;
      }else{
        rapportinoDaSalvare.allDay           = true;
      }
      rapportinoDaSalvare.cestello          = this.rapportinoForm.get('cestello').value;
      rapportinoDaSalvare.note              = this.rapportinoForm.get('note').value;

      if(this.isFileUploaded){
        rapportinoDaSalvare.allegati = true;
      }else{
        rapportinoDaSalvare.allegati = false;
      }
      if(this.useCreate){
        this.rapportiniService.crea(rapportinoDaSalvare).subscribe(rapportino=>{
          if(this.isFileUploaded){
            //TODO: CARICA FILE SU STORAGE
              this.selectedFiles.forEach(allegato => {
                this.googleStorageService.uploadAllegatoRapportinoToGoogleStorage(allegato,this.selectedInterventoFirestore.codice);
              });
              this.dialogRef.close({ success: true});
          }else{
            this.dialogRef.close({ success: true});
          }

        },error => {
          this.dialogRef.close({success: false});
          this.messageService.add({
            severity: "info",
            summary: "Errore",
            detail: "Errore in fase di compilazione del rapportino", life:10000
          });
         });
      }else{
        this.rapportiniService.modifica(rapportinoDaSalvare).subscribe(rapportino=>{
          this.dialogRef.close({
            success: true
          });
        },error => {
          this.dialogRef.close({
            success: false
          });
          this.messageService.add({
            severity: "info",
            summary: "Errore",
            detail: "Errore in fase di compilazione del rapportino", life:10000
          });
         });
      }
      //this.dialogRef.close({ success: true });
    }
  }


}
