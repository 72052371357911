<form [formGroup]="creaGruppoTecniciForm">
    <div class="grid formgrid p-fluid">

        <div class="field mb-4 col-12">
          <label for="descrizione" class="font-medium text-900">DESCRIZIONE</label>
          <span class="p-input-icon-left">
              <i class="pi pi-id-card"></i>
            <input id="descrizione" type="text"  formControlName="descrizione" pInputText>
          </span>
        </div>

    </div>
</form>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!creaGruppoTecniciForm.valid"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button>
</div>
