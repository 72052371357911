<div class="master" #widgetHolder>
  <div class="mapholder" id="{{componentDivId}}"></div>

  <div id="currentTarget" *ngIf="this.selectdPosition" class="surface-card shadow-2 border">
    {{this.selectdPosition.posdesc}}
  </div>

    <div id="maptools">

    <div class="maptool" *ngIf="controlsMyPosition">
        <i class="material-symbols-outlined small-icon" style="color: #467248; cursor: pointer" pTooltip="Mia posizione"
               tooltipPosition="top" (click)="centerOnUserLocation()">my_location</i>
    </div>

    <div class="maptool" *ngIf="controlsFullExtent">
        <i class="material-symbols-outlined small-icon" style="color: #467248; cursor: pointer" pTooltip="Visuale iniziale"
               tooltipPosition="top" (click)="returnToExtent()">aspect_ratio</i>
    </div>

    <div class="maptool" *ngIf="controlsMaximize">
        <i class="material-symbols-outlined small-icon" style="color: #467248; cursor: pointer" pTooltip="Schermo intero"
               tooltipPosition="top" (click)="toggleFullscreen()">settings_overscan</i>
    </div>

  </div>



    <div id="maplayers">
      <p-dropdown
        [options]="bgSelect"
        [(ngModel)]="activeBackground"
        optionLabel="name"
        optionValue="name"
        placeholder="Layer di sfondo"
        (onChange)="changeBackgroundLayer($event.value)"
      />
  </div>

  <div id="selectionBar" class="">
    <div id="ricercaSostegno" *ngIf="showSostegni" class="toolbarElement surface-card shadow-2 border">
      <label for="sostegno" class="font-medium text-900">Sostegno</label>
      <span class="p-input-icon-left" style="margin-left: 0.5em">
          <p-autoComplete
            id="sostegno"
            [(ngModel)]="selezioneSostegno"
            [suggestions]="idSostegni"
            (completeMethod)="filterSostegni($event)"
            (onSelect)="findSostegno()"
          />
        </span>

      <i class="material-symbols-outlined small-icon"
         style="vertical-align:middle; margin-left: 0.5em; color: #467248; cursor: pointer" pTooltip="Apri"
         tooltipPosition="top" (click)="findSostegno()">
        outbound
      </i>

    </div>

    <div id="ricercaIndirizzo" class="toolbarElement surface-card shadow-2">

      <label for="indirizzo" class="font-medium text-900">Indirizzo</label>
      <span class="p-input-icon-left" style="margin-left: 0.5em;">
              <p-autoComplete
                [(ngModel)]="indirizzo_base"
                id="indirizzo"
                [suggestions]="filteredIndirizzi"
                field="indirizzo"
                (completeMethod)="geocodificaIndirizzo($event)"
                (onSelect)="onIndirizzoSelect($event)"
                placeholder="Seleziona indirizzo"
              >
            </p-autoComplete>
            </span>

      <label style="margin-left: 0.5em;" for="civico" class="font-medium text-900">Civico</label>
      <input id="civico" [(ngModel)]="indirizzo_civico" (ngModelChange)="onCivicoSelect($event)" type="text" style="width: 4em; margin-left: 0.5em" pInputText autocomplete="off">


      <i class="material-symbols-outlined small-icon"
         style="vertical-align:middle; margin-left: 0.5em; color: #467248; cursor: pointer" pTooltip="Apri"
         tooltipPosition="top" (click)="forcePositionFromCurrentAddress()">
        outbound
      </i>
    </div>


  </div>

  <div id="currentSelection" *ngIf="this.selectdPosition"></div>

</div>
