 <div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <span class="text-xl font-medium text-2000" style="color: rgb(13, 123, 138)">TIPI DI SEGNALAZIONE</span>

    </div>

    <div class="flex justify-center">

  </div>

  <div class="flex justify-end md:justify-end">
    <span class="p-input-icon-left mr-2">
      <i class="pi pi-search"></i>
      <input type="text" pInputText placeholder="Cerca" style="border-radius: 20px;"
        (input)="filtraPerNome($event)" [disabled]="isLoadingResults" />
      </span>
      <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;"
      (click)="loadTipiSegnalazione()" [disabled]="isLoadingResults"></button>
      <button pButton type="button" icon="pi pi-plus" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(74, 89, 169); color: white;"  tooltipPosition="top" pTooltip="Nuovo Tipo Segnalazione"
      (click)="openCreaTipoSegnalazione()"
      >
    </button>
    </div>
    </div>

  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>

    <p-table #tipiSegnalazioneDT styleClass="p-datatable-sm" [value]="tipiSegnalazione" dataKey="id"
      [selectionPageOnly]="true" [rowHover]="true" [rows]="30" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[30,50,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
      [globalFilterFields]="['id','label','areaCompetenza.label','giorniRisoluzione']" >
<!-- [sortField]="'denominazione'" [sortOrder]="'1'" -->
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="label">
            <div class="flex justify-content-between align-items-center">
              TIPO
              <p-sortIcon field="label"></p-sortIcon>
           </div>
          </th>
          <th class="text-center" pSortableColumn="areaCompetenza.label">
              AREA COMPETENZA
              <p-sortIcon field="areaCompetenza.label"></p-sortIcon>
          </th>
          <th class="text-center" pSortableColumn="giorniRisoluzione">
             GIORNI RISOLUZIONE
            <p-sortIcon field="giorniRisoluzione"></p-sortIcon>
        </th>
          <th class="text-center">Azioni</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>
          <!-- <td> <span style="font-weight: 500; font-weight: bold;">{{row.label | uppercase}}</span> -->
            <td >
              <p-tag
                    [value]="row.label | uppercase"
                    [style]="{
                      'background-color': row.colore ,
                      'color': '#fcfcfc' ,
                      'border-radius': '6px',
                      'padding': '.25em .5rem',
                      'font-weight': '700',
                      'font-size': '13px',
                      'letter-spacing': '.3px;',
                      'cursor': 'pointer',
                    }"

                  class="mr-1"
              ></p-tag>
            </td>
            <td class="text-center" >
              <p-tag
                    [value]="row.areaCompetenza.label"
                    [style]="{
                      'background-color': row.areaCompetenza.colore ,
                      'color': '#ffffff' ,
                      'border-radius': '6px',
                      'padding': '.25em .5rem',
                      'font-weight': '700',
                      'font-size': '13px',
                      'letter-spacing': '.3px;',
                      'cursor': 'pointer',
                    }"

                  class="mr-1"
              ></p-tag>
            </td>
            <td class="text-center"> <span style="font-weight: 500; font-weight: bold;">{{row.giorniRisoluzione}}</span>
          <td class="text-center">
            <button pButton type="button" style="color:rgb(44, 113, 144);" icon="pi pi-pencil" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Modifica tipo segnalazione"
              (click)="openModificaTipoSegnalazioneDialog(row)">
            </button>
            <button *ngIf="row.deletable" pButton type="button" style="color:rgb(249, 2, 2);" icon="pi pi-eraser" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Elimina tipo segnalazione"
              (click)="openEliminaTipoSegnalazioneDialog(row)">
            </button>
          </td>


        </tr>
      </ng-template>

    </p-table>

  </div>
</div>

<p-toast position="top-center"></p-toast>
