import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ActivitiesMapEventListener, ActivitiesMapWidget} from "../../lib/activities-map-widget";
import {HttpClient} from "@angular/common/http";
import {Segnalazione_Firestore} from "../../models/firestore/segnalazioneFirestore.model";
import {UserService} from "../../services/user.service";
import {Intervento_Firestore} from "../../models/firestore/interventoFirestore.model";
import {SostegniService} from "../../services/sostegni.service";
import {ComuneDTO} from "../../models/comune.model";
import {InterventoSegnalazione_FireStore} from "../../models/firestore/interventosegnalazionefirestore.model";
import {PosizioneSegnalazione} from "../localize-activity-map-widget/localize-activity-map-widget.component";
import {ComuneMappa} from "../../models/territori_api";
import {ComuniService} from "../../services/comuni.service";


@Component({
  selector: 'app-activities-map-widget',
  templateUrl: './activities-map-widget.component.html',
  styleUrl: './activities-map-widget.component.scss'
})
export class ActivitiesMapWidgetComponent implements OnInit, OnChanges, AfterViewInit {

  static COMPONENTID = 0;

  isReady: boolean;
  componentDivId: string;

  mapwidget: ActivitiesMapWidget;
  @Input({ required: true }) segnalazioni: Segnalazione_Firestore[];
  @Input({ required: true}) interventi: Intervento_Firestore[];
  @Input({ required: false}) comune: ComuneDTO;
  @Input({ required: false}) posizione: PosizioneSegnalazione;
  @Output() onSelectSegnalazione = new EventEmitter<Segnalazione_Firestore>();
  @Output() onSelectIntervento = new EventEmitter<InterventoSegnalazione_FireStore>();
  @ViewChild('widgetHolder') widgetHolder: ElementRef;

  @Input({required: false}) controlsMaximize: boolean = true;
  @Input({required: false}) controlsMyPosition: boolean = true;
  @Input({required: false}) controlsFullExtent: boolean = true;

  territories: ComuneMappa[];
  // sostegni: Sostegno[];

  showSegnalazioni: boolean = true;
  showInterventi: boolean = true;

  popup: PopupParams;
  showPopup: boolean;

  activeBackground: string;
  bgSelect: {
    name: string,
    layer: any
  }[];

  constructor (private http: HttpClient, private userService: UserService, private sostegniService: SostegniService, private comuniService: ComuniService) {
    this.territories = null;
    // this.sostegni = null;
    this.isReady = false;

    // console.log("user info: ", userService.getUser());
    this.componentDivId = ActivitiesMapWidgetComponent.getComponentId();
    // console.log("INIT MAP WIDGET COMPONENT w id " + this.componentDivId);
    this.showPopup = true;

    this.bgSelect = [];
    this.activeBackground = null;
  }

  static getComponentId (): string {
    const out = ActivitiesMapWidgetComponent.COMPONENTID;
    ActivitiesMapWidgetComponent.COMPONENTID++;
    return "activities_mapholder_" + out;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const sigChange = changes.segnalazioni;
    const intChange = changes.interventi;
    // console.log('dati aggiornati', this.segnalazioni.length, this.interventi.length);
    if(sigChange && (sigChange.previousValue && sigChange.currentValue) && (sigChange.previousValue != sigChange.currentValue)){
       // console.log('segnalazioni cambiate', this.segnalazioni, this.interventi);
        this.mapwidget?.updateSegnalazioni(this.segnalazioni);
        this.mapwidget?.updateInterventi(this.getInterventiSegnalazioni());
        // this.mapwidget.fitToData();
    }
    if(intChange && (intChange.previousValue && intChange.currentValue) && (intChange.previousValue != intChange.currentValue)){
       // console.log('interventi cambiati', this.segnalazioni, this.interventi);
        this.mapwidget?.updateInterventi(this.getInterventiSegnalazioni());
        // this.mapwidget.fitToData();
    }
}

  checkUpdateReadiness () {
    // console.log("checking mapwidget readiness from ", this.isReady);
    if (!this.isReady) {
      // console.log("updating mapwidget readiness?");
      if (this.territories != null && this.segnalazioni != null && this.interventi != null) {
        this.isReady = true;
        this.onReady();
      }
    }
  }

  ngOnInit() {

  }

  ngAfterViewInit() {

    // repositioning popup when map container changes (e.g. the component is displayed/hidden)
    const mapel = this.widgetHolder.nativeElement;
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target == mapel) {
          if (entry.target["offsetHeight"] == 0 || entry.target["offsetWidth"] == 0) {
            this.popup = null;
            return;
          }

        }
      }
      this.reposPopup();
    });
    observer.observe(mapel);
    this.loadTerritories();

  }

  changeBackgroundLayer (layername: string) {
    this.mapwidget.setBgLayerByName(layername);
  }

  updateBackgroundSelect () {
    this.bgSelect = this.mapwidget.bglayers.map((item) => {
      return {
        name: item.get("name"),
        layer: item
      }
    });
    this.activeBackground = this.mapwidget.backgroundLayer.get("name");
  }

  onReady () {
    this.mapwidget = new ActivitiesMapWidget(this.territories, []);
    this.mapwidget.initMap(this.componentDivId);
    this.mapwidget.updateSegnalazioni(this.segnalazioni);
    this.mapwidget.updateInterventi(this.getInterventiSegnalazioni());

    this.mapwidget.setVisibilitySegnalazioni(this.showSegnalazioni);
    this.mapwidget.setVisibilityInterventi(this.showInterventi);
    this.mapwidget.fitToData();

    const ui = this;
    this.mapwidget.addListener(new class extends ActivitiesMapEventListener {


      onSelectSegnalazione(segnalazione: Segnalazione_Firestore) {
        // console.log("picked segnalazione", segnalazione);
        const lonlat = [segnalazione.coordinate.longitude, segnalazione.coordinate.latitude];
        // console.log("segnalazione at lonlat ", lonlat);
        ui.popup = {
          style: ui.getPopupPositionStyle(lonlat),
          lonlat: lonlat,
          segnalazione: segnalazione
        }

      }

      onSelectInterventoSegnalazione(intervento: InterventoSegnalazione_FireStore) {
        const lonlat = [intervento.segnalazione.coordinate.longitude, intervento.segnalazione.coordinate.latitude];
        // console.log("segnalazione at lonlat ", lonlat);
        ui.popup = {
          style: ui.getPopupPositionStyle(lonlat),
          lonlat: lonlat,
          intervento: intervento
        }
      }

      onMoveStateChange(moving: boolean) {
        // console.log("hiding popups? ", moving);
        if ((!moving) && ui.popup) {
          ui.reposPopup();
        }
        ui.showPopup = !moving;

      }

      onZoomChange(level: number) {
        super.onZoomChange(level);
        console.log("zoom changed to ", level);
      }

      onLayersChange() {
        super.onLayersChange();
        console.log("layers changed to ", ui.mapwidget.bglayers);
        ui.updateBackgroundSelect();
      }

    }());

    this.updateBackgroundSelect();
  }

  getPopupPositionStyle (lonlat: number[]): {[key: string]: string} {
    const mapel = this.widgetHolder.nativeElement;
    const mapelW = mapel.offsetWidth;
    const mapelH = mapel.offsetHeight;
    // we HIDE by default so the flow is simplified later on
    let styledict: {[key: string]: string} = {
      "display": "none"
    };
    if (mapelW != 0 && mapelH != 0) {
      // -Infinity is a failsafe for unpredictable map widget states
      // (sometimes it will fail the coords/pixel conversion even if the map is already sized
      const relCoords = this.mapwidget.getPixelPositionForCoords(lonlat) ?? [-Infinity, -Infinity];
      // TODO: should add some buffer maybe or other adjustment to take into account its own content size?
      if (relCoords[0] >= 0 && relCoords[1] >= 0 && relCoords[0] <= mapelW && relCoords[1] <= mapelH) {
        styledict = {
          "position": "absolute",
          "top": `${relCoords[1]}px`
        };

        if (relCoords[0] < mapelW / 2 || relCoords[0] < 0) {
          styledict["left"] = `${relCoords[0]}px`;
        } else {
          styledict["right"] = `${mapelW - relCoords[0]}px`;
        }

      }
    }
    return styledict;

  }

  reposPopup () {
    if (!this.popup) {
      return;
    } else {
      this.popup.style = this.getPopupPositionStyle(this.popup.lonlat);
    }
  }

  loadTerritories () {
    this.comuniService.getComuniMappa().subscribe((data: ComuneMappa[]) => {
      if (this.userService.isSegnalatore) {
        const userIstat = this.userService.getUser().comune.codiceIstat;
        this.territories = data.filter((comune) => {
          return comune.codiceISTAT == userIstat;
        });
      } else if (this.comune) {
        this.territories = data.filter((comune) => {
          return comune.codiceISTAT == this.comune.codiceIstat;
        });
      } else {
        this.territories = [...data];
      }
      this.checkUpdateReadiness();
    });
  }

  getInterventiSegnalazioni (): InterventoSegnalazione_FireStore[] {

    const segnalazioniById = {};
    for (let segnalazione of this.segnalazioni) {
      segnalazioniById[segnalazione.codice] = segnalazione;
    }

    return this.interventi.map((intervento) => {
      return {
        intervento: intervento,
        segnalazione: segnalazioniById[intervento.codiceSegnalazione]
      }
    });

  }

  setVisibilitySegnalazioni (visible: boolean) {
    this.showSegnalazioni = visible;
    this.mapwidget?.setVisibilitySegnalazioni(visible);
  }

  setVisibilityInterventi (visible: boolean) {
    this.showInterventi = visible;
    this.mapwidget?.setVisibilityInterventi(visible);
  }


  closePopup () {
    this.popup = null;
  }

  openPopupContent (popup: PopupParams) {
    if (popup.segnalazione) {
      this.onSelectSegnalazione.emit(popup.segnalazione);
    } else if (popup.intervento) {
      this.onSelectIntervento.emit(popup.intervento);
    }
  }

  centerOnUserLocation () {
    navigator.geolocation.getCurrentPosition((position) => {
      // console.log("position", position.coords.latitude, position.coords.longitude);
      this.mapwidget.centerOnCoords(position.coords.latitude, position.coords.longitude);
    });
  }

  returnToExtent () {
    this.mapwidget.resetView();
  }

  toggleFullscreen () {
    if (this.widgetHolder.nativeElement == document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      this.widgetHolder.nativeElement.requestFullscreen();
    }
  }

}

export interface PopupParams {
  lonlat: number[];
  style: { [key: string]: string };
  segnalazione?: Segnalazione_Firestore;
  intervento?: InterventoSegnalazione_FireStore;
}
