<div class="surface-card p-1 shadow-2 border-round">


    <full-calendar [options]='agendaOptions()'>
      <ng-template #eventContent let-arg>
        <b>{{ arg.timeText }}</b>
        <i>{{ arg.event.title }}</i>
      </ng-template>
    </full-calendar>
    <div class="p-3 mt-2"  style="display: flex; justify-content: center; gap: 20px; align-items: center;">

      <div>
        <p-multiSelect class="filtro_risorse"
          [showClear]="true"
          [filter]="true"
          [showToggleAll]="true"
          [options]="gruppiTecnici"
          display="chip"
          optionLabel="descrizione"
          optionValue="id"
          [(ngModel)]="filtroGruppi"
          (onChange)="refreshVisibleResources()"
          placeholder="Gruppi"
          [maxSelectedLabels]="3"
        />

      </div>

      <div >
        <p-multiSelect class="filtro_risorse"
          [showClear]="true"
          [filter]="true"
          [showToggleAll]="true"
          [options]="risorseAtes"
          display="chip"
          optionLabel="nome"
          optionValue="id"
          [(ngModel)]="filtroRisorse"
          (onChange)="refreshVisibleResources()"
          placeholder="Risorse"
          [maxSelectedLabels]="3"
        />

      </div>


      <div style="display: flex; gap: 20px; align-items: center;">

        <div style="display: flex; align-items: center;">
          <img src="/assets/icons/evento_agenda_intervento.svg" alt="Intervento" width="24" height="24" style="filter: invert(1) grayscale(1); transform: scaleX(-1);">
          <span style="color: #70A0AF; margin-left: 8px;">Intervento</span>
      </div>

      <div style="display: flex; align-items: center;">
        <img src="/assets/icons/evento_agenda_manutenzione.svg" alt="Manutenzione" width="24" height="24" style="filter: invert(1) grayscale(1);">
              <span style="color: #C05746; margin-left: 8px;"> Manutenzione</span>
          </div>
          <div style="display: flex; align-items: center;">
            <img src="/assets/icons/evento_agenda_giustificativo.svg" alt="Giustificativo" width="24" height="24" style="filter: invert(1) grayscale(1);">
            <span style="color: #F29E4C; margin-left: 8px;"> Giustificativo</span>
          </div>
          <div style="display: flex; align-items: center;">
            <img src="/assets/icons/evento_agenda_altro.svg" alt="Altro" width="24" height="24" style="filter: invert(1) grayscale(1);">
              <span style="color: #A0C1B9; margin-left: 8px;"> Altro</span>
          </div>
      </div>
  </div>
</div>

<!--
<p-dialog [(visible)]="mostraConfermaModificaEvento" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}">
  <ng-template pTemplate="header">
      <div class="flex align-items-center">
          <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
              <i class="pi pi-info text-lg"></i>
          </span>
          <span class="font-medium text-2xl text-900">Modifica pianificazione evento</span>
      </div>
  </ng-template>
  <p class="line-height-3 p-0 m-0">
      Confermi di voler modificare la data dell'evento?
  </p>
  <ng-template pTemplate="footer">
      <div class=" border-top-1 surface-border pt-3">
          <button pButton pRipple icon="pi pi-times" (click)="annullaModificaEvento()" label="Annulla" class="p-button-warning" ></button>
          <button pButton icon="pi pi-check" (click)="confermaModificaEvento()" label="Conferma" class="p-button-success"></button>
      </div>
  </ng-template>
</p-dialog>-->

<p-confirmDialog>
    <ng-template pTemplate="message" let-message>
        <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
            <p>{{ message.message }}</p>
        </div>
    </ng-template>
</p-confirmDialog>

<p-toast position="top-center"></p-toast>
