import { ComuneDTO } from "./comune.model";
import { GruppoTecnici } from "./gruppoTecnici.model";
import { RuoloUtente } from "./utente.ruolo.model";

export class Utente {
    id: number;
    nome: string;
    idAuth0:string;
    password:string;
    mail:string;
    ruolo:RuoloUtente;
    comune:ComuneDTO;
    gruppoTecnici:GruppoTecnici;
    deletable:boolean;
  }
