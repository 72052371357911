import {
  AfterViewInit,
  Component,
  HostBinding,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import {ConfirmationService, MenuItem, Message, MessageService, PrimeIcons, SelectItemGroup} from "primeng/api";
import { environment } from '../../../environments/environment';
import { Location } from "@angular/common";

import {
  DialogService,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { ActivatedRoute, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Menu } from "primeng/menu";

import { Timestamp, onSnapshot } from "firebase/firestore";
import { UtilService } from "src/app/services/util.service";
import { ComuniService } from "src/app/services/comuni.service";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { UserService } from "src/app/services/user.service";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import {FiltroIntervento, Intervento_Firestore} from "src/app/models/firestore/interventoFirestore.model";
import { STILI_AREA_COMPETENZA_SEGNALAZIONI, STILI_STATO_INTERVENTI, STILI_TIPO_INTERVENTI } from "src/app/shared/stili";
import { InterventiService } from "src/app/services/interventi.service";
import { Intervento } from "src/app/models/intervento.model";
import { StiliService } from "src/app/services/stili.service";
import { CreaInterventoComponent } from "./crea/crea_intervento";
import { CalendarOptions, DateSelectArg, EventClickArg } from "@fullcalendar/core";
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import italiano from '@fullcalendar/core/locales/it';
import { TabViewChangeEvent } from "primeng/tabview";
import { DettagliInterventoComponent } from "./dettagli/dettagli-intervento.component";
import {Segnalazione_Firestore} from "../../models/firestore/segnalazioneFirestore.model";
import {ActivitiesMapWidgetComponent} from "../../components/activities-map-widget/activities-map-widget.component";
import { RapportinoComponent } from "./rapportino/rapportino.component";
import { StatoIntervento } from "src/app/models/stato.intervento.model";
import { GalleriaAllegatiInterventoComponent } from "./galleria-allegati/galleria_allegati_interventi.component";
import { PianificaInterventoComponent } from "./pianifica-intervento/pianifica_intervento";
import { StoricoInterventoComponent } from "./storico/storico-intervento.component";
import tippy from "tippy.js";
import exportFromJSON from "export-from-json";
import { TipoIntervento } from "src/app/models/tipoIntervento.model";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";

declare const CSVBoxImporter: any;

@Component({
  selector: "interventi",
  templateUrl: "./interventi.component.html",
  styleUrls: ["./interventi.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class InterventiComponent implements OnInit, AfterViewInit {

  @HostBinding("class.mobile") isMobile: boolean;
  @ViewChild("interventiDT", { static: true }) interventiDT: any;
  @ViewChild("menuAzioniTiered", { static: true }) azioniMenuTiered: Menu;

  @ViewChild("menuStati") menuStati!: Menu;
  @ViewChild("menuTipi") menuTipi!: Menu;
  @ViewChild("menuComuni") menuComuni!: Menu;
  @ViewChild("menuAree") menuAree!: Menu;

  @ViewChild(ActivitiesMapWidgetComponent) mapwidget: ActivitiesMapWidgetComponent;


  isLoadingResults = false;
  dialogConfig;

  private collection_interventi:string = environment.firebase.collection_interventi;

  public isAdmin:boolean = false;
  public isSuperAdmin  :boolean = false;
  public isOperatore  :boolean = false;
  public isTecnico  :boolean = false;
  public mostraConfermaChiusuraIntervento     :boolean = false;
  public mostraConfermaSospensioneIntervento  :boolean = false;
  public mostraConfermaSbloccaIntervento  :boolean = false;

  public interventiFiltrati: Intervento_Firestore[] = [];
  public interventi: Intervento_Firestore[] = [];
  public selectedIntervento:Intervento_Firestore;
  public segnalazioni: Segnalazione_Firestore[] = [];

  protected menuAzioniItems!: MenuItem[];

  protected statiIntervento: MenuItem[] = [];
  protected comuniIntervento: MenuItem[] = [];
  protected tipiIntervento: TipoIntervento[] = [];
  protected areeCompetenza: AreaCompetenza[] = [];



  opzioniAreeCompetenza: {value: string, label: string}[] = [];
  opzioniStati: {value: string, label: string}[] = [];
  opzioniTipoArea: SelectItemGroup[] = [];
  opzioniTipo: {value: string, label: string}[] = [];
  opzioniComuni: {value: string, label: string}[] = [];

  protected selectedArea: string | null = null;
  protected selectedTipo: string | null = null;
  protected selectedStato: string | null = null;
  protected selectedComune: string | null = null;

  protected stiliStatointerventi          = STILI_STATO_INTERVENTI;
  protected stiliAreaCompetenzaIntervento = STILI_AREA_COMPETENZA_SEGNALAZIONI;
  protected stiliTipointerventi           = STILI_TIPO_INTERVENTI;

  public filtroInterventi: FiltroIntervento;



  //filtriVisualizzazione: FiltrointerventiModel;
  calendarOptions = signal<CalendarOptions>({
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    headerToolbar: {
      left: 'prev today next',
      center: 'title',
      right: ''
    },
    initialView: 'dayGridMonth',
    //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: 0,
    height: '600px',
    locale: italiano,
    events: [],
    select: this.openCreaInterventoDaCalendario.bind(this),
    eventClick: this.clickSuIntervento.bind(this),
    eventChange: this.modificaPianificazioneIntervento.bind(this),
    eventDidMount: this.displayDetails.bind(this),
     validRange: {
      //start: new Date()
    },

   // eventsSet: this.handleEvents.bind(this)
    /* you can update a remote database when these fire:
    eventAdd:

    eventRemove:
    */
  });

  public filtroStato:string;
  public filtroIntervento:string;
  public filtroSegnalazione:string;
  protected filterIsSet:Boolean = false;
  public filtroComune: string;

  msgs: Message[] = [];
  ref: DynamicDialogRef;

  constructor(
    private logger: NGXLogger,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private interventiService:InterventiService,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilService,
    private comuniService: ComuniService,
    private tipiInterventoService: TipiInterventoService,
    private location: Location,
    private areeCompetenzaService:AreeCompetenzaService,
    private userService:UserService,
    private storageService:GoogleStorageService,
    public stiliService:StiliService,
    private tipoInterventoService: TipiInterventoService,
  ) {
    this.detectDevice();
    this.loadTipiIntervento();

    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };

    this.filtroInterventi = {};

  }
  loadTipiIntervento() {
    this.isLoadingResults = true;
    this.tipoInterventoService.get().subscribe(tipiInterventoFromDB =>{
      this.tipiIntervento = tipiInterventoFromDB;
      this.isLoadingResults = false;
    });
  }
  getTipoInterventoColor(tipoIntervento:string):string{
    const tipo = this.tipiIntervento.filter((tipo)=>{return tipo.label == tipoIntervento})[0] as TipoIntervento;
    if(tipo!=null){
      return tipo.colore;
    }
    return "";

  }
  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnInit(): void {
    this.logger.info("***NG ON INIT @ INTERVENTI ****");
    this.isOperatore = this.userService.isOperatore;
    this.isAdmin    = this.userService.isAdmin;
    const urlParamIntervento = this.activatedRoute.snapshot.paramMap.get('intervento');
    const urlParamStato = this.activatedRoute.snapshot.paramMap.get('stato');
    const urlParamSegnalazione = this.activatedRoute.snapshot.paramMap.get('segnalazione');
    if(urlParamIntervento){
      this.filtroIntervento = urlParamIntervento;
      //this.filtroInterventi["_all"] = urlParamIntervento;
      this.filterIsSet = true;
    }else if(urlParamStato){
      //this.filtroInterventi["stato"] = [urlParamStato];
      this.filtroStato = urlParamStato;
      this.filterIsSet = true;
    }else if(urlParamSegnalazione){
      this.filtroSegnalazione = urlParamSegnalazione;
      // this.filtroInterventi["_all"] = urlParamSegnalazione;
      this.filterIsSet = true;
    }else{
    }
    this.loadMenuFiltri();

    this.loadInterventi();
    this.loadAreeCompetenza();
    this.loadBindSegnalazioni();

    this.applyFilters();



  }

  ngAfterViewInit () {
    this.mapwidget?.setVisibilitySegnalazioni(false);
  }

  onTabChange(event: TabViewChangeEvent) {

    const selectedTabId = event.index;
    if(!this.userService.isSegnalatore && !this.userService.isTecnico){
      if(selectedTabId==0){

      }else if(selectedTabId==1){
        //this.loadInterventiData();
        //this.loadTicketData();
      }else if(selectedTabId==2){
        this.loadInterventiPerCalendario();
      }
    }
  }


  static fullDayFromString (datestring: string) {
    const dmy = datestring.split("/").map(value => parseInt(value));
    const value = new Date(dmy[2], dmy[1] - 1, dmy[0]);
    value.setHours(24, 0, 0);
    return value;
  }
  loadAreeCompetenza() {
    this.areeCompetenzaService.get().subscribe(areeCompetenzaFromDB =>{
      this.areeCompetenza = areeCompetenzaFromDB;
    });
  }
  getAreaCompetenzaColor(areaCompetenzaRecord:string){
    const areaCompetenza = this.areeCompetenza.filter((areaCompetenza)=>{return areaCompetenza.label == areaCompetenzaRecord})[0] as AreaCompetenza;
    if(areaCompetenza!=null){

      return areaCompetenza.colore;
    }
    return "";
  }

  loadInterventiPerCalendario(){
    const classes = {
      "INTERVENTO": "evento_intervento",
      "MANUTENZIONE": "evento_manutenzione",
      "GIUSTIFICATIVO": "evento_giustificativo",
      "ALTRO": "evento_altro"
    }
    const calendarEvents = this.interventi.filter((intervento) => {
      return intervento.pianificato;
    }).map((intervento: Intervento_Firestore) => {
        // Converti la data di pianificazione in oggetto Date
        // console.log("intervento: ", intervento);
        // const endDateFullDay = evento.fineStringa ? InterventiComponent.fullDayFromString(evento.fineStringa) : evento.fine.toDate();
        // console.log(intervento.inizioPianificazione.toDate(), intervento.finePianificazione.toDate());
        const dateFrom = new Date(intervento.inizioPianificazione.toDate());
        dateFrom.setHours(0, 0, 0);
        const dateTo = new Date(intervento.finePianificazione.toDate());
        dateTo.setHours(24, 0, 0);

        const pianificatoDate = new Date(intervento.pianificato.toDate());


        // Aggiungi un giorno alla data
        const nextDayDate = new Date(pianificatoDate);
        nextDayDate.setDate(pianificatoDate.getDate() + 1);

        // Formatta la data risultante come stringa ISO e prendi solo la parte della data
        const formattedDate = nextDayDate.toISOString().split('T')[0];
        /* start: evento.inizio.toDate(),
        end: evento.fine.toDate(),
        resourceId: evento.idTecnico.toString(),
        backgroundColor: this.getEventoBackgroundColor(evento.categoria),
        borderColor: this.getEventoBorderColor(evento.categoria),
        resourceEditable: false,
        allDay: true,
        classNames: ['agenda-event-style', classes[evento.categoria] ?? "evento_altro"],
        _src: evento */
        return {
          id: intervento.id.toString(),
          // title: intervento.codice ,
          // date: formattedDate,
          start: dateFrom,
          end: dateTo,
          backgroundColor: this.getEventoBackgroundColor("INTERVENTO"), // Colore di sfondo dell'evento
          borderColor: this.getEventoBorderColor("INTERVENTO"), // Colore del bordo dell'evento
          resourceEditable: false,
          //textColor: intervento.eseguito ? '#000000' : '#000000',
          //classNames: ['full-calendar-custom-event'],
          classNames: ['agenda-event-style', classes["INTERVENTO"] ?? "evento_altro"],
          allDay: true,
          _src: intervento


    }});

    this.calendarOptions.update(options => ({
      ...options,
      events: calendarEvents // Aggiungi gli eventi al segnale
    }));

  }
  getEventoBackgroundColor(categoria):string{
    switch (categoria) {
      case 'INTERVENTO':
        return '#70A0AF';
      case 'MANUTENZIONE':
        return '#C05746';
      case 'GIUSTIFICATIVO':
        return '#F29E4C';
      default:
        return '#A0C1B9';
    }
  }
  getEventoBorderColor(categoria):string{
    switch (categoria) {
      case 'INTERVENTO':
        return '#70A0AF';
      case 'MANUTENZIONE':
        return '#C05746';
      case 'GIUSTIFICATIVO':
        return '#F29E4C';
      default:
        return '#A0C1B9';
    }
  }
  modificaPianificazioneIntervento(clickInfo: EventClickArg) {
    this.logger.info("modificaPianificazioneIntervento");

  }
  clickSuIntervento(clickInfo: EventClickArg) {
    if(clickInfo.event.id){
      this.interventiService.getByID(clickInfo.event.id).subscribe(interventoFromDB =>{
        // console.log("opening event details da calendario", interventoFromDB, "from", clickInfo.event);
        this.ref = this.dialogService.open(DettagliInterventoComponent, {
          data: {
             intervento: interventoFromDB,
            interventoFS: clickInfo.event
          },
          header: "Dettagli Intervento "+ interventoFromDB.codice,
          width: this.isMobile ? "95%" : "70%",
          height: "95%",
          baseZIndex: 10000,
          closable: true,
          draggable: false,
        });
        this.ref.onClose.subscribe((creationResult) => {
          /* this.logger.info(creationResult);
          if (creationResult&& creationResult.success) {
            this.loadInterventiPerCalendario();
            this.messageService.add({severity:'success', summary:'', detail: ' Intervento creato con successo!', life:3000});
          } */
        });
      });

    }


  }

  openCreaInterventoDaCalendario(selectInfo: DateSelectArg){

    const dateEnd = new Date(selectInfo.end);
    dateEnd.setDate(dateEnd.getDate()-1);
    this.ref = this.dialogService.open(CreaInterventoComponent, {
     data: {
      isDateValorizzate:true,
       dataInizioIntervento: selectInfo.start,
       dataFineIntervento: dateEnd
     },
     header: "Crea Intervento",
     width: this.isMobile ? "95%" : "70%",
     height: "95%",
     baseZIndex: 10000,
     closable: true,
     draggable: false,
   });
   this.ref.onClose.subscribe((creationResult) => {
     if (creationResult&& creationResult.success) {
       this.loadInterventiPerCalendario();
       this.messageService.add({severity:'success', summary:'', detail: ' Intervento creato con successo!', life:3000});
     }
   });
  }


  loadMenuFiltri(){

    this.areeCompetenzaService.get().subscribe(areeCompetenzaDaDB => {
      this.opzioniAreeCompetenza = areeCompetenzaDaDB.map((area) => {
        return {
          value: area.label,
          label: area.label
        }
      });
    });
    this.tipiInterventoService.get().subscribe(tipiIntervento=> {

      const done = [];
      this.opzioniTipo = Object.values(tipiIntervento).map((tipo: any) => {
        if (done.indexOf(tipo.label) == -1) {
          done.push(tipo.label);
          return {
            value: tipo.label,
            label: tipo.label
          }
        } else {
          return null;
        }
      }).filter((item) => {
        return item != null
      }).sort((itemA, itemB) => {
        if (itemA.label == "ALTRO") {
          return 1;
        } else if (itemB.label == "ALTRO") {
          return -1;
        } else {
          return 0;
        }
      });


    });
    this.utilService.getStatiIntervento().subscribe(statiIntervento => {
      this.opzioniStati = statiIntervento.map((stato) => {
        return {
          value: stato.label,
          label: stato.label
        }
      }).sort((itemA, itemB) => {
        return itemA.label.localeCompare(itemB.label);
      });
    });

    this.comuniService.get().subscribe(comuni => {
      this.opzioniComuni = comuni.map((comune) => {
        return {
          value: comune.codiceIstat,
          label: comune.denominazione
        }
      }).sort((itemA, itemB) => {
        return itemA.label.localeCompare(itemB.label);
      });

    });

  }


  /** carica le segnalazioni come riferimento per costruire la mappa */
  loadBindSegnalazioni() {
    const collectionRef = this.firestore.collection(environment.firebase.collection_segnalazioni).ref;
    onSnapshot(collectionRef, (qs) => {
       this.segnalazioni = qs.docs.map((item) => {
        return item.data() as Segnalazione_Firestore;
      });
    });
  }

  loadInterventi(){
    this.isLoadingResults = true;
    const collectionRef = this.firestore.collection(this.collection_interventi).ref;
    let query  ;
    if(this.filterIsSet){
      if(this.filtroIntervento){
         // this.logger.info("---> loadinterventi CON FILTRO Intervento");
          query = collectionRef.where("codice", "==", this.filtroIntervento).orderBy('creato', 'desc');
      }else if(this.filtroStato){
         // this.logger.info("---> loadinterventi CON FILTRO STATO");
          query = collectionRef.where("stato", "==", this.filtroStato).orderBy('creato', 'desc');
      }else if(this.filtroSegnalazione){
         // this.logger.info("---> loadinterventi CON FILTRO STATO + UTENTE");
          query = collectionRef.where("codiceSegnalazione", "==", this.filtroSegnalazione).orderBy('creato', 'desc');
      }
    }else{
        query = collectionRef.orderBy('modificato', 'desc');
    }

    onSnapshot(query, (qs) => {
      this.interventi = [];
      this.isLoadingResults = false;

      const comuni_listed = []; // elenco istat già incontrati


      this.interventi = qs.docs.map((item) => {
        const intervento = item.data() as Intervento_Firestore;
        return intervento;
      });
      console.log("lista interventi", this.interventi);

      this.applyFilters();
      // qs.forEach((doc) => {
      //   let intervento = doc.data() as Intervento_Firestore;
      //   this.interventi.push(intervento);
      //   this.logger.info(intervento);

       /*  if (comuni_listed.indexOf(Intervento.codiceISTATComune) == -1) {
          this.fChoicesComune.push({
            label: Intervento.comune,
            value: Intervento.codiceISTATComune
          });
          comuni_listed.push(Intervento.codiceISTATComune);

        } */

      // console.log("filter comune", this.fChoicesComune);
      // console.log("sigs", this.interventi);
      //this.applyFilters();
    });

   /*  this.interventiService.get().subscribe((interventi) => {
      this.isLoadingResults = false;
      this.interventi = interventi;
    }); */
   }

   applyFilters () {
    this.interventiFiltrati = this.interventi.filter((int: Intervento_Firestore) => {
      const allfilter = this.filtroInterventi._all?.toLowerCase() ?? null;
      const allfilterable = this.getInterventoStringFilterableFields(int);
      if (
        ((this.filtroInterventi.comune?.length ?? 0) > 0 && this.filtroInterventi.comune.indexOf(int.comune) == -1)
        || ((this.filtroInterventi.area?.length ?? 0) > 0 && this.filtroInterventi.area.indexOf(int.areaSegnalazione) == -1)
        || ((this.filtroInterventi.stato?.length ?? 0) > 0 && this.filtroInterventi.stato.indexOf(int.stato) == -1)
        || ((this.filtroInterventi.tipo?.length ?? 0) > 0 && this.filtroInterventi.tipo.indexOf(int.tipo) == -1)
        || ((!!allfilter) && !(Object.values(allfilterable).some((value) => {
              return `${value}`.toLowerCase().includes(allfilter);
          })))
      ) {
        return false;
      }
      return true;
    });
    // console.log(`filtrati ${this.interventiFiltrati.length}/${this.interventi.length}`);


   }

  showPulsanteAzioni(Intervento:Intervento_Firestore):boolean{
    //todo: eventuali condizioni che non permettono operazioni
    return true;
  }

  reloadDataRemovingFilter(){
    this.filterIsSet = false;
    this.filtroStato = null;
    this.filtroIntervento = null;
    this.location.replaceState('/interventi');
    this.loadInterventi();
}

  async openCaricaAzioni(  event: any, Intervento: Intervento_Firestore ): Promise<void> {
    this.selectedIntervento = Intervento;
    this.loadAzioni(event, Intervento);
    this.azioniMenuTiered.toggle(event);
  }

  goToSegnalazione(codiceSegnalazione: string){
     this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate(['segnalazioni/segnalazione/'+codiceSegnalazione]));
  }

  loadAzioni(event:any, intervento:Intervento_Firestore) {
    this.menuAzioniItems = [];

     if(this.userService.isOperatore || this.userService.isAdmin){
      this.menuAzioniItems.push(
        {
          label: "Dettagli",
          icon: PrimeIcons.INFO_CIRCLE,
          style: { 'font-size': '13px'},
          command: () => this.apriDettagliIntervento(intervento),
        },
        {
           label: "Storico",
           icon: PrimeIcons.HISTORY,
           style: { 'font-size': '13px'},
           command: () => this.openStoricoIntervento(intervento),
         },
         {
          label: "Segnalazione",
          icon: PrimeIcons.MEGAPHONE,
          style: { 'font-size': '13px'},
          command: () => this.goToSegnalazione(intervento.codiceSegnalazione),
        }
         );
         if(intervento.stato==='PIANIFICATO'){
          if(intervento.hasRapportino){

          }else{
            this.menuAzioniItems.push(
              {
                label: "Crea Rapportino",
                icon: PrimeIcons.FILE_CHECK,
                style: { 'font-size': '13px', 'font-weight': 'bold'},
                command: () => this.openRapportinoIntervento(intervento),
              },
              {
                label: "Sospendi",
                icon: PrimeIcons.PAUSE_CIRCLE,
                style: { 'font-size': '13px', 'font-weight': 'bold'},
                command: () => this.sospendiIntervento(intervento),
              },
              {
                label: "Elimina",
                icon: PrimeIcons.ERASER,
                style: { 'font-size': '13px', 'font-weight': 'bold'},
                command: () => this.eliminaIntervento(intervento),
              },
            );
          }
       }else if(intervento.stato==='DA PIANIFICARE'){
         this.menuAzioniItems.push(

          {
            label: "Pianifica",
            icon: PrimeIcons.CALENDAR_PLUS,
            style: { 'font-size': '13px', 'font-weight': 'bold'},
            command: () => this.pianificaIntervento(intervento),
          },
          {
            label: "Elimina",
            icon: PrimeIcons.ERASER,
            style: { 'font-size': '13px', 'font-weight': 'bold'},
            command: () => this.eliminaIntervento(intervento),
          },
         );
       }else if(intervento.stato==='IN ATTESA'){
        this.menuAzioniItems.push(
          {
            label: "Sblocca",
            icon: PrimeIcons.PLAY,
            style: { 'font-size': '13px', 'font-weight': 'bold'},
            command: () => this.sbloccaIntervento(intervento),
          },

         );
       }
    }else if(this.userService.isTecnico){
      this.menuAzioniItems.push(
        {
          label: "Dettagli",
          icon: PrimeIcons.INFO_CIRCLE,
          style: { 'font-size': '13px'},
          command: () => this.apriDettagliIntervento(intervento),
        },
        {
           label: "Storico",
           icon: PrimeIcons.HISTORY,
           style: { 'font-size': '13px'},
           command: () => this.openStoricoIntervento(intervento),
         }
      );
      if(intervento.stato==='PIANIFICATO'){
        if(intervento.hasRapportino){

        }else{
          this.menuAzioniItems.push(
            {
              label: "Crea Rapportino",
              icon: PrimeIcons.FILE_CHECK,
              style: { 'font-size': '13px', 'font-weight': 'bold'},
              command: () => this.openRapportinoIntervento(intervento),
            }

          );
        }
      }
    }
  }

  getEseguitoColor(intervento:Intervento_Firestore): string{
    if (intervento.eseguito && intervento.eseguireEntro) {
      if (!intervento.eseguireEntro || intervento.eseguito <= intervento.eseguireEntro) {
        return 'text-green-900';
      } else if (intervento.eseguireEntro && intervento.eseguito > intervento.eseguireEntro) {
        return 'text-red-800';
      }
    }
    return 'text-blue-900';
  }

  checkIfInterventoSingleDay(intervento:Intervento_Firestore):boolean{
    if(intervento.inizioPianificazione && intervento.finePianificazione){
      const inizioPianificazione   = intervento.inizioPianificazione.toDate();
      const finePianificazione     = intervento.finePianificazione.toDate();
      return inizioPianificazione.getUTCFullYear() === finePianificazione.getUTCFullYear() &&
      inizioPianificazione.getUTCMonth() === finePianificazione.getUTCMonth() &&
      inizioPianificazione.getUTCDate() === finePianificazione.getUTCDate();
    }
   return true;
  }

  getPianificazioneInterventoContent(intervento:Intervento_Firestore):string{
    let content:string="";
    if(intervento.inizioPianificazione && intervento.finePianificazione){
      const inizioPianificazione   = intervento.inizioPianificazione.toDate();
      const finePianificazione     = intervento.finePianificazione.toDate();
      if(inizioPianificazione.getUTCFullYear() === finePianificazione.getUTCFullYear() &&
          inizioPianificazione.getUTCMonth() === finePianificazione.getUTCMonth() &&
          inizioPianificazione.getUTCDate() === finePianificazione.getUTCDate()){
            content =this.formattaData(intervento.inizioPianificazione);
          }else{
            content = this.formattaData(intervento.inizioPianificazione) +" - " +this.formattaData(intervento.finePianificazione);
          }
    }
   return content;
  }

  mostraTecniciIntervento(intervento:Intervento_Firestore): string{
    let tecniciString = "Assegnato a:\n";
    if(intervento.tecnici.length == 1){
      tecniciString+=intervento.tecnici[0].nome.toUpperCase();
    }else{
      intervento.tecnici.forEach((tecnico, index) => {
        tecniciString += tecnico.nome.toUpperCase();
        if (index < intervento.tecnici.length - 1) {
          tecniciString += "\n";
      }
      });
    }
    return tecniciString;
  }

  confermaChiusuraIntervento(){
    this.mostraConfermaChiusuraIntervento = false;
    this.isLoadingResults = true;
    let interventoDaAggiornare = new Intervento();
    interventoDaAggiornare.autore           = this.userService.getUser();
    let nuovoStatoIntervento:StatoIntervento = new StatoIntervento();
    nuovoStatoIntervento.id = 4;
    interventoDaAggiornare.id = this.selectedIntervento.id;
    interventoDaAggiornare.stato = nuovoStatoIntervento;
    this.interventiService.modifica(interventoDaAggiornare).subscribe(interventoAggiornato =>{
      this.messageService.add({severity:'success', summary:'', detail: 'Intervento modificato con successo!', life:3000});
      this.isLoadingResults = false;
    },error => {
      this.messageService.add({
        severity: "info",
        summary: "Errore",
        detail: "Errore in fase di aggiornamento dell'intervento", life:10000
      });
      this.isLoadingResults = false;
     });

  }
  confermaSospensioneIntervento(){
    this.mostraConfermaSospensioneIntervento = false;
    this.isLoadingResults = true;
    let interventoDaAggiornare = new Intervento();
    interventoDaAggiornare.autore           = this.userService.getUser();
    let nuovoStatoIntervento:StatoIntervento = new StatoIntervento();
    nuovoStatoIntervento.id = 3;
    interventoDaAggiornare.id = this.selectedIntervento.id;
    interventoDaAggiornare.stato = nuovoStatoIntervento;
    this.interventiService.modifica(interventoDaAggiornare).subscribe(interventoAggiornato =>{
      this.messageService.add({severity:'success', summary:'', detail: 'Intervento modificato con successo!', life:3000});
      this.isLoadingResults = false;
    },error => {
      this.messageService.add({
        severity: "info",
        summary: "Errore",
        detail: "Errore in fase di aggiornamento dell'intervento", life:10000
      });
      this.isLoadingResults = false;
     });

  }
  confermaSbloccoIntervento(){
    this.mostraConfermaSbloccaIntervento = false;
    this.isLoadingResults = true;
    let interventoDaAggiornare = new Intervento();
        interventoDaAggiornare.autore           = this.userService.getUser();
    let nuovoStatoIntervento:StatoIntervento = new StatoIntervento();
    nuovoStatoIntervento.id = 2;
    interventoDaAggiornare.id = this.selectedIntervento.id;
    interventoDaAggiornare.stato = nuovoStatoIntervento;
    this.interventiService.modifica(interventoDaAggiornare).subscribe(interventoAggiornato =>{
      this.messageService.add({severity:'success', summary:'', detail: 'Intervento modificato con successo!', life:3000});
      this.isLoadingResults = false;
    },error => {
      this.messageService.add({
        severity: "info",
        summary: "Errore",
        detail: "Errore in fase di aggiornamento dell'intervento", life:10000
      });
      this.isLoadingResults = false;
     });

  }

  sospendiIntervento(intervento:Intervento_Firestore) {
    this.mostraConfermaSospensioneIntervento = true;
    this.selectedIntervento = intervento;
  }
  chiudiIntervento(intervento:Intervento_Firestore) {
    this.mostraConfermaChiusuraIntervento = true;
    this.selectedIntervento = intervento;
  }
  sbloccaIntervento(intervento:Intervento_Firestore) {
    this.mostraConfermaSbloccaIntervento = true;
    this.selectedIntervento = intervento;
  }

  mostraAllegatiIntervento(intervento:Intervento_Firestore){
    this.ref = this.dialogService.open(GalleriaAllegatiInterventoComponent, {
      data: {
        intervento: intervento,
      },
      header: "ALLEGATI INTERVENTO "+intervento.codice.toUpperCase(),
      width:  "99%",
      height: "99%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((downloadResult) => {
      if (downloadResult&& downloadResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: 'Allegati scaricati con successo!', life:3000});
      }
    });
  }

  openRapportinoIntervento(intervento:Intervento_Firestore){
    //this.logger.info(intervento);

    this.ref = this.dialogService.open(RapportinoComponent, {
      data: {
        intervento: intervento,
      },
      header: "RAPPORTINO INTERVENTO "+intervento.codice,
      width:  "99%",
      height: "99%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((rapportinoResult) => {
        if(rapportinoResult && rapportinoResult.success){
          this.messageService.add({severity:'success', summary:'', detail: 'Rapportino caricato con successo!', life:3000});

        }else{

        }
    });


  }


  openStoricoIntervento(intervento:Intervento_Firestore) {
    this.ref = this.dialogService.open(StoricoInterventoComponent, {
      data: {
        intervento: intervento,
      },
      header: "STORICO DELL'INTERVENTO "+intervento.codice,
      width:  "70%",
      height: "80%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((closeResltu) => {

    });
  }

  apriDettagliIntervento(intervento:Intervento_Firestore) {
    this.interventiService.getByID(intervento.id.toString()).subscribe(interventoFromDB =>{
      this.ref = this.dialogService.open(DettagliInterventoComponent, {
        data: {
           intervento: interventoFromDB,
           interventoFS:intervento
        },
        header: "Dettagli Intervento "+ interventoFromDB.codice,
        width: this.isMobile ? "95%" : "70%",
        height: "95%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((creationResult) => {
        /* this.logger.info(creationResult);
        if (creationResult&& creationResult.success) {
          this.loadInterventiPerCalendario();
          this.messageService.add({severity:'success', summary:'', detail: ' Intervento creato con successo!', life:3000});
        } */
      });
    });

  }

  eliminaIntervento(intervento:Intervento_Firestore) {
    this.confirmationService.confirm({
      message: `Confermi di voler ELIMINARE l'intervento <strong>${intervento.codice.toUpperCase()}</strong>?`,
      header: "Eliminazione INTERVENTO",
      icon: "pi pi-eraser",
      acceptLabel: "CONFERMA",
      acceptButtonStyleClass: "p-button-success",
      rejectLabel: "ANNULLA",
      rejectButtonStyleClass: "p-button-warning",
      accept: () => {
         //segnalazione.stato = nuovoStatoSegnalazione;
        this.isLoadingResults = true;
        this.interventiService
          .elimina(intervento.id.toString())
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
              summary: "Errore" , life:10000,
                detail: "Errore in fase di eliminazione dell'intervento",
              });
            },
            complete: () => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "success",
                summary: "" , life:10000,
                detail: "Intervento eliminato",
              });
              //this.loadSegnalazioni();
            },
        });


      },
      reject: () => {
      },
    });
  }

  pianificaIntervento(intervento:Intervento_Firestore) {
     this.ref = this.dialogService.open(PianificaInterventoComponent, {
      data: {
        intervento: intervento,
      },
      header: "PIANIFICA INTERVENTO "+intervento.codice,
      width:  "99%",
      height: "99%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((creationResult) => {
      if (creationResult&& creationResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: 'Intervento pianificato con successo!', life:3000});

      }
    });
  }
  apriInterventi(Intervento:Intervento_Firestore) {

  }


  // potrebbe andare ampliato a seconda di che campi rendiamo più visibili
  getInterventoStringFilterableFields (int: Intervento_Firestore): {[key: string]: string} {
    return {
      codice: int.codice,
      codiceSegnalazione: int.codiceSegnalazione,
      comune: int.comune,
      creato: this.formattaData(int.creato),
      eseguito: this.formattaData(int.eseguito),
      pianificato: this.formattaData(int.pianificato),
      stato: int.stato,
      tipo: int.tipo,
      note: int.note,
    }
  }

   formattaData(data:Timestamp):string{
    if(data!=null){
      const date = data.toDate();
      const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`; // Ad esempio, formato DD/MM/YYYY
      return formattedDate;
    }
    return "";

  }
  renderEventContent(arg) {
    const icon = document.createElement('span');
    icon.className = 'material-icons'; // Usando le icone di Material, per esempio
    icon.innerText = 'event'; // Nome dell'icona, ad esempio 'event' per un'icona di calendario

    return { domNodes: [icon] };
  }
  displayDetails (info: any) {
    const evento: Intervento_Firestore = info.event._def.extendedProps["_src"];
    const content = evento ? (evento.areaSegnalazione + ": " + evento.tipo + " @ " + evento.comune.toUpperCase()) : "";
    // evitiamo tooltip vuoti che possono confondere?
    if (content.trim().length > 0) {
      tippy(info.el, {
        content: content,
        theme: "light-border",
        zIndex: 9999999,
        appendTo: document.body,
        allowHTML:true
      });
    }
  }


   loadinterventiFromFirestore(){
    this.isLoadingResults = true;

    const collectionRef = this.firestore.collection(this.collection_interventi).ref;
    const query = collectionRef.orderBy("stato");
    onSnapshot(query, (qs) => {
      this.interventi = [];
      this.isLoadingResults = false;
      qs.forEach((doc) => {
        let Intervento = doc.data() as Intervento_Firestore;
        //this.interventi.push(Intervento);

      });
    });
   }


   openCreaInterventoDialog(){
     this.ref = this.dialogService.open(CreaInterventoComponent, {
      data: {
        //pratica: praticaDaAggiornare,
      },
      header: "Crea Intervento",
      width: this.isMobile ? "95%" : "70%",
      height: "95%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((creationResult) => {
      if (creationResult&& creationResult.success) {
        this.loadInterventi();
        this.messageService.add({severity:'success', summary:'', detail: ' Intervento creato con successo!', life:3000});
      }
    });
   }

   filtraInterventi(event: any){
    this.filtroInterventi._all = event.target.value?.trim().length > 0 ? event.target.value.trim() : null;
    this.applyFilters();
    // this.interventiDT.filterGlobal(event.target.value, 'contains')
   }

   getStatoClass(stato: string): string {
    if (stato === "CREATA") {
      return "stato-Intervento-nuova";
    }else if (stato === "ASSEGNATA") {
      return "stato-Intervento-assegnata";
    }else if (stato === "IN CARICO") {
      return "stato-Intervento-in_carico";
    }else if (stato === "RIFIUTATA") {
      return "stato-Intervento-rifiutata";
    }else if (stato === "ACCETTATA") {
      return "stato-Intervento-accettata";
    } else if (stato === "IN LAVORAZIONE") {
      return "stato-Intervento-in_lavorazione";
    }
    return "";
  }
  formattaDurata(minuti: number): string {
    const ore = Math.floor(minuti / 60);
    const restantiMinuti = minuti % 60;
    let risultato = '';
    if (ore > 0) {
      risultato += `${ore}h `;
    }
    if (restantiMinuti > 0) {
      risultato += `${restantiMinuti}m`;
    }
    return risultato.trim();
  }

  getAreaCompetenzaClass(area: string): string {
    if (area === "CONDIZIONAMENTO - RISCALDAMENTO") {
      return "area-competenza-Intervento-condizionamento";
    }/* else if (area === "ASSEGNATA") {
      return "stato-Intervento-assegnata";
    }else if (area === "IN CARICO") {
      return "stato-Intervento-in_carico";
    }else if (area === "RIFIUTATA") {
      return "stato-Intervento-rifiutata";
    }else if (area === "ACCETTATA") {
      return "stato-Intervento-accettata";
    } else if (area === "IN LAVORAZIONE") {
      return "stato-Intervento-in_lavorazione";
    } */
    return "";
  }


  downloadAsExcel() {

    const now = new Date();
    let datestr = `${now.getFullYear()}${(""+now.getMonth()+1).padStart(2,"0")}${(""+now.getDate()).padStart(2,"0")}`;
    let timestr = `${(""+now.getHours()).padStart(2,"0")}${(""+now.getMinutes()).padStart(2,"0")}${(""+now.getSeconds()).padStart(2,"0")}`;
    const fileName = `solvify_interventi_${datestr}_${timestr}`;
    const exportType =  exportFromJSON.types.xls;
    const fields = ["codice", "tipo", "segnalazione", "areaSegnalazione", "tipoSegnalazione", "creato", "comune",
      "eseguireEntro", "pianificato", "inizioPianificazione", "finePianificazione", "eseguito", "durata", "tecnici", "note", "modificato", "stato"];
    const data = this.interventiFiltrati.map((int: Intervento_Firestore) => {
      return {
        codice: int.codice,
        tipo: int.tipo,
        segnalazione: int.codiceSegnalazione,
        areaSegnalazione: int.areaSegnalazione,
        tipoSegnalazione: int.tipoSegnalazione,

        creato: this.formattaData(int.creato),
        comune: int.comune,

        eseguireEntro: this.formattaData(int.eseguireEntro),
        pianificato: this.formattaData(int.pianificato),
        inizioPianificazione: this.formattaData(int.inizioPianificazione),
        finePianificazione: this.formattaData(int.finePianificazione),
        eseguito: this.formattaData(int.eseguito),
        durata: this.formattaDurata(int["durata"]) ?? "",

        tecnici: int.tecnici?.map((tecnico) => tecnico.nome).join(", ") ?? "",
        note: int.note ?? "",

        modificato: this.formattaData(int.modificato),
        stato: int.stato,

      }
    });
    exportFromJSON({data, fileName, fields, exportType });
  }





}
