

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { MessageService } from "primeng/api";


import {
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { GruppoTecnici } from "src/app/models/gruppoTecnici.model";
import { TipoSegnalazione } from "src/app/models/tipoSegnalazione.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { GruppiTecniciService } from "src/app/services/gruppiTecnici.service";
import { TipiSegnalazioneService } from "src/app/services/tipiSegnalazione.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  selector: "modifica-gruppo_tecnici",
  templateUrl: "./dialog-modifica-gruppo_tecnici.component.html",
  styleUrls: ["../gruppi_tecnici.component.css"],
})
export class ModificaGruppoTecniciComponent implements OnInit {
  protected _onDestroy = new Subject<void>();

  protected modificaGruppoTecniciForm: FormGroup;
  protected selectedGruppoTecnici:GruppoTecnici;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private gruppoTecniciService:GruppiTecniciService,
  ) {
    this.selectedGruppoTecnici = this.config.data.gruppoTecnici;
  }

  ngOnInit(): void {
    this.modificaGruppoTecniciForm = this._formBuilder.group({
      descrizione :    [this.selectedGruppoTecnici.descrizione, [Validators.required]],

    });
  }


  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let gruppoTecniciDaModificare: GruppoTecnici = new GruppoTecnici();
          gruppoTecniciDaModificare.id                   = this.selectedGruppoTecnici.id;
          gruppoTecniciDaModificare.descrizione                = this.modificaGruppoTecniciForm.value.descrizione;


       this.gruppoTecniciService.modifica(gruppoTecniciDaModificare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di modifica del gruppo di tecnici!", life:10000
          });
        }
      );
    }
  }
}
