

import {
    Component,
    OnInit,
  } from "@angular/core";
  import { NGXLogger } from "ngx-logger";
  import { ConfirmationService, MessageService } from "primeng/api";


  import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
  } from "primeng/dynamicdialog";


  import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
  import { Subject } from "rxjs";
  import { HttpClient } from "@angular/common/http";
import { Segnalazione_Firestore } from "src/app/models/firestore/segnalazioneFirestore.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UtentiService } from "src/app/services/utenti.service";
import { UserService } from "src/app/services/user.service";
import { StatoSegnalazione } from "src/app/models/stato.segnalazione.model";


@Component({
    selector: "app-prendi_in_carico-segnalazione",
    templateUrl: './prendi_in_carico-segnalazione.component.html',
    styleUrls: ['../segnalazioni.component.css']
  })
  export class PrendInCaricoSegnalazioneComponent implements OnInit {

    priorities = [
      { label: 'Bassa', value: 'BASSA' },
      { label: 'Media', value: 'MEDIA' },
      { label: 'Alta', value: 'ALTA' },
      { label: 'Critica', value: 'CRITICA' },
    ];

    protected _onDestroy = new Subject<void>();
    protected segnalazione:Segnalazione_Firestore;
    protected prendiInCaricoSegnalazioneForm: FormGroup;

    protected ref: DynamicDialogRef;
    isLoadingResults = false;

    constructor(
        private logger: NGXLogger,
        public dialogRef: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public segnalazioniService:SegnalazioniService,
        public confirmationService:ConfirmationService,
        private messageService:MessageService,
        public dialogService: DialogService,
        private _formBuilder: FormBuilder,
        private utentiService: UserService
      ) {
    }

    getPriorityIcon(priority: string): string {
      switch (priority) {
        case 'BASSA': return 'pi pi-check-circle green';
        case 'MEDIA': return 'pi pi-exclamation-circle yellow';
        case 'ALTA': return 'pi pi-exclamation-triangle orange';
        case 'CRITICA': return 'pi pi-times-circle red';
        default: return '';
      }
    }

    ngOnInit(): void {
        this.segnalazione = this.config.data.segnalazione;

        this.prendiInCaricoSegnalazioneForm = this._formBuilder.group({
          priorita:  new FormControl({value: null, disabled: false}, Validators.required),
        });
    }




    conferma(conferma: boolean) {
      if (!conferma) {
        this.dialogRef.close({ success: false });
      } else {
        let segnalazioneDaModificare = new Segnalazione();
        let nuovoStatoSegnalazione = new StatoSegnalazione();
        nuovoStatoSegnalazione.id = 2;
            segnalazioneDaModificare.id = this.segnalazione.id;
            segnalazioneDaModificare.autore = this.utentiService.getUser();
            segnalazioneDaModificare.operatoreInCarico = this.utentiService.getUser();
            if(this.segnalazione.stato!=='PIANIFICATA' ){
              segnalazioneDaModificare.stato  = nuovoStatoSegnalazione;
            }
            //segnalazioneDaModificare.stato = nuovoStatoSegnalazione;
            segnalazioneDaModificare.priorita = this.prendiInCaricoSegnalazioneForm.value.priorita.value.toUpperCase();
         this.segnalazioniService
          .modifica(segnalazioneDaModificare)
          .subscribe({
            next: (v) => {},
            error: (e) => {
              this.isLoadingResults = false;
              this.messageService.add({
                severity: "info",
              summary: "Errore" , life:10000,
                detail: "Errore in fase di presa in carico della segnalazione",
              });
              this.dialogRef.close({
                success: false
              });
            },
            complete: () => {
              this.isLoadingResults = false;

              this.dialogRef.close({
                success: true
              });
            },
        });






      }
    }
  }
