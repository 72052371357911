 <div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <span class="text-xl font-medium text-2000" style="color: rgb(13, 123, 138)">GRUPPI TECNICI</span>

    </div>

    <div class="flex justify-center">

  </div>

  <div class="flex justify-end md:justify-end">
    <span class="p-input-icon-left mr-2">
      <i class="pi pi-search"></i>
      <input type="text" pInputText placeholder="Cerca" style="border-radius: 20px;"
        (input)="filtraPerNome($event)" [disabled]="isLoadingResults" />
      </span>
      <button pButton pRipple type="button" icon="pi pi-refresh" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(175, 209, 175); color: white;"
      (click)="loadGruppi()" [disabled]="isLoadingResults"></button>
      <button pButton type="button" icon="pi pi-plus" class="p-button-rounded mr-2 p-button-outlined" style="background-color: rgb(74, 89, 169); color: white;"  tooltipPosition="top" pTooltip="Nuovo Gruppo Tecnici"
      (click)="openCreaGruppoTecnici()"
      >
    </button>
    </div>
    </div>

  <div fxFlex="95%">
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>

    <p-table #gruppiDT styleClass="p-datatable-sm" [value]="gruppi" dataKey="id"
      [selectionPageOnly]="true" [rowHover]="true" [rows]="30" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[30,50,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
      currentPageReportTemplate="{first} - {last} di {totalRecords}" [filterDelay]="0"
      [globalFilterFields]="['id','descrizione']" >
<!-- [sortField]="'denominazione'" [sortOrder]="'1'" -->
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="descrizione">
            <div class="flex justify-content-between align-items-center">
              DESCRIZIONE
              <p-sortIcon field="descrizione"></p-sortIcon>
           </div>
          </th>

          <th class="text-center">Azioni</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row>
        <tr>

            <td > <span style="font-weight: 500; font-weight: bold;">{{row.descrizione}}</span>
            <td class="text-center">
              <button pButton type="button" style="color:rgb(44, 113, 144);" icon="pi pi-pencil" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Modifica gruppo di tecnici"
                (click)="openModificaGruppoDialog(row)">
              </button>
              <button *ngIf="row.deletable" pButton type="button" style="color:rgb(249, 2, 2);" icon="pi pi-eraser" class="p-button-rounded p-button-text" tooltipPosition="top" pTooltip="Elimina gruppo di tecnici"
                (click)="openEliminaGruppoDialog(row)">
              </button>
            </td>

        </tr>
      </ng-template>

    </p-table>

  </div>
</div>

<p-toast position="top-center"></p-toast>
