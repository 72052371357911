import {Injectable} from '@angular/core';
import {HttpClient, HttpParamsOptions} from '@angular/common/http';
import {catchError, from, Observable, of, shareReplay, tap} from 'rxjs';
import {environment} from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import {ComuneDTO} from '../models/comune.model';
import {AreaCompetenza} from '../models/areaCompetenza.model';
import {ComuneCreationDescriptor, ComuneIstat, ComuneMappa} from "../models/territori_api";
import {Firestore, onSnapshot} from "firebase/firestore";
import {Ticket_Firestore} from "../models/firestore/ticketFirestore.model";
import {AngularFirestore} from "@angular/fire/compat/firestore";


@Injectable({
  providedIn: 'root',
})
export class ComuniService {
  apiUrl: string = environment.apiUrl + "comune";

  hasMapBinding: boolean;
  mappaComuni: ComuneMappa[];

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private firestore: AngularFirestore,
  ) {

    this.hasMapBinding = false;
    this.mappaComuni = null;

  }


  get(): Observable<any> {
    const headers = {
      // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }

  aggiungiAreaDiCompetenza(comune: ComuneDTO, areaDiCompetenza: AreaCompetenza): Observable<any> {
    let url = this.apiUrl + "/" + comune.id + "/area-competenza";
    const headers = {
      // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(url, areaDiCompetenza, httpOptions);
  }

  rimuoviAreaDiCompetenza(comune: ComuneDTO, areaDiCompetenza: AreaCompetenza): Observable<any> {
    let url = this.apiUrl + "/" + comune.id + "/area-competenza/" + areaDiCompetenza.id;
    const headers = {
      // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(url, httpOptions);
  }

  elimina(idComune: string): Observable<any> {
    const headers = {
      //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl + "/" + idComune, httpOptions);

  }

  aggiungiComune(comune: ComuneCreationDescriptor): Observable<any> {
    const httpOptions = {
      headers: {},
      reportProgress: true
    };

    return this.http.post(`${this.apiUrl}`, comune, httpOptions);
  }

  getComuniMappa(): Observable<ComuneMappa[]> {

    if (!this.hasMapBinding) {
      // this.getComuniMappaAPI(true);
      const collectionRef = this.firestore.collection(environment.firebase.collection_mappacomuni).ref;
      onSnapshot(collectionRef, (qs) => {
        this.mappaComuni = qs.docs.map((item) => {
          return item.data() as ComuneMappa;
        });
      });
      this.hasMapBinding = true;

      const promise: Promise<ComuneMappa[]> = collectionRef.get().then((qs) => {
        this.mappaComuni = qs.docs.map((item) => {
          return item.data() as ComuneMappa;
        });
        return [...this.mappaComuni];
      });

      return from(promise);

    } else {
      return of([...this.mappaComuni]);
    }


  }


}
