<div >

  <div class="field mt-2 mb-2 col-3">
    <label for="regioni" class="font-medium text-900 text-green-600">Regione</label>
    <!-- <span class="p-input-icon-left"> </span> -->
        <p-dropdown styleClass="inputWide" [scrollHeight]="'480px'" id="regioni" [options]="optionsRegioni"
                    optionLabel="denominazione" [showClear]="true"
                    placeholder="Scegli la regione" appendTo="body"
                    (onChange)="onSelectRegione($event.value)">
          </p-dropdown>

  </div>

  <div class="field mt-2 mb-2 col-3">
    <label for="province" class="font-medium text-900 text-green-400">Provincia</label>
    <!-- <span class="p-input-icon-left"></span> -->
        <p-dropdown styleClass="inputWide" id="province" [options]="optionsProvince" optionLabel="denominazione"
                    [showClear]="true" appendTo="body" [scrollHeight]="'480px'"
                    placeholder="Scegli la provincia"  [disabled]="selectedRegione == null"
                    (onChange)="onSelectProvincia($event.value)">
          </p-dropdown>

  </div>

  <div class="field mt-2 mb-2 col-3">
    <label for="comuni" class="font-medium text-900 text-green-800">Comune</label>
    <!-- <span class="p-input-icon-left"></span> -->
        <p-dropdown styleClass="inputWide" optionDisabled="censito" [scrollHeight]="'480px'" id="comuni"
                    [options]="optionsComuni" optionLabel="comune" [showClear]="true" appendTo="body"
                    placeholder="Scegli il comune" [disabled]="selectedProvincia == null"
                    (onChange)="onSelectComune($event.value)">
          </p-dropdown>

  </div>
  <div class="field mt-2 mb-2 col-3">
    <label for="areeCompetenza" class="font-medium text-900 text-blue-800">Aree di Competenza</label>
    <!-- <span class="p-input-icon-left"> </span> -->
      <p-multiSelect
      styleClass="inputWide"
          [showClear]="true"
          [showToggleAll]="true"
          [options]="optionsAreeCompetenza"
          display="chip"
          optionLabel="label"
          optionValue="id"
          [(ngModel)]="selectedAreeCompetenza"
          placeholder="Scegli le aree di competenza"
          [disabled]="selectedComune == null"
        />
      <!-- <p-dropdown styleClass="inputWide" [scrollHeight]="'480px'" id="areeCompetenza"
                    [options]="optionsAreeCompetenza" optionLabel="label" [showClear]="true" appendTo="body"
                    placeholder="Scegli le aree di competenza" [disabled]="selectedComune == null"
                    >
          </p-dropdown> -->

  </div>

  <div class="field col-12 text-center mt-5 d-flex" style="height: 100%;">
      <button type="button" pButton pRipple label="ANNULLA" icon="pi pi-times" class="px-5 py-3 w-auto mr-3" severity="warning" (click)="conferma(false)" [disabled]="saving"></button>
      <button type="button" pButton pRipple label="CONFERMA" icon="pi pi-check" class="px-5 py-3 w-auto" severity="success" (click)="conferma(true)" [disabled]="saving || !selectedComune" ></button>
  </div>
</div>
