export const environment = {
  name: "TEST",
  version: '1.4.10',
  //apiUrl: "http://localhost:9092/api/v1/",
  apiUrl: "https://ates-be.map-hosting.it/api/v1/",
  auth0: {
    clientId: "OLIAfDFkmbqe5D8Q1JiudH6BD2yy472G",
    domain: "ates-energia.eu.auth0.com",
    audience: "https://ates-energia-be.map-hosting.it",
    redirectUri: window.location.origin,
  },
  firebase: {
    apiKey: "AIzaSyCiI8-3_Q182eqORGX4Pxqx79l8SYY3y-8",
    authDomain: "ates-energia.firebaseapp.com",
    projectId: "ates-energia",
    storageBucket: "ates-energia.appspot.com",
    messagingSenderId: "469150412467",
    appId: "1:469150412467:web:a50dd4e744517b9c9c0ac6",
    measurementId: "G-MXTFJH2YK9",
    collection_segnalazioni:"segnalazioni_test",
    collection_interventi:"interventi_test",
    collection_eventi:"eventi_test",
    collection_mappacomuni:"confini_comuni_censiti",
  },
  storageBucket: "ates-energia.appspot.com",
};
