import {
  Component,
  HostBinding,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, Message, MessageService, PrimeIcons } from "primeng/api";
import { Location } from "@angular/common";


import {
  DialogService,
  DynamicDialogRef,
} from "primeng/dynamicdialog";

import { ActivatedRoute, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { UserService } from "src/app/services/user.service";

import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import {CalendarOptions, DateSelectArg, EventClickArg, EventSourceInput, SlotLabelContentArg} from "@fullcalendar/core";
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import italiano from '@fullcalendar/core/locales/it';
import { UtentiService } from "src/app/services/utenti.service";
import { Utente } from "src/app/models/utente.model";
import { CreaEventoComponent } from "./crea/crea-evento";
import { environment } from "src/environments/environment";
import {Evento_Firestore, EventoFirestore} from "src/app/models/firestore/eventoFirestore.model";
import { Evento } from "src/app/models/evento.model";
import { EventiService } from "src/app/services/eventi.service";
import { ModificaEventoComponent } from "./modifica/modifica-evento";
import { FullCalendarComponent } from "@fullcalendar/angular";
import {DateRange, EventDef, EventImpl} from "@fullcalendar/core/internal";
import {onSnapshot, Timestamp} from "firebase/firestore";
import tippy from "tippy.js";
import firebase from "firebase/compat";
import Unsubscribe = firebase.Unsubscribe;
import {AuthService} from "@auth0/auth0-angular";
import {GruppiTecniciService} from "../../services/gruppiTecnici.service";
import {GruppoTecnici} from "../../models/gruppoTecnici.model";

@Component({
  selector: "app-agenda",
  templateUrl: "./agenda.component.html",
  styleUrls: ["./agenda.component.css"],
  providers: [DialogService, ConfirmationService],
})
export class AgendaComponent implements OnInit {

  @HostBinding("class.mobile") isMobile: boolean;
  @ViewChild("calendar") calendarComponent: FullCalendarComponent; // Usa ViewChild per accedere al componente


  isLoadingResults = false;
  dialogConfig;

  gruppiTecnici:GruppoTecnici[] = [];
  risorseAtes:Utente[]=[];
  filtroRisorse:number[] = [];
  filtroGruppi:number[] = [];

  msgs: Message[] = [];
  ref: DynamicDialogRef;

  private collection_eventi:string = environment.firebase.collection_eventi;


  private previousEventState: any = null;

  static DAYS = ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"];

  public agendaOptions = signal<CalendarOptions>({
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
      resourceTimelinePlugin
    ],
    headerToolbar: {
      left: 'prev today next',
      center: 'title',
      right: ''
    },
    resourceAreaHeaderContent:"Risorse",
    //initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    height: '700px',
    locale: italiano,
    events: [],
    initialView: 'resourceTimelineWeekly',
    views: {
        resourceTimelineWeekly: {
          type: 'resourceTimeline',
          duration: { days: 7 },
          slotDuration: '24:00:00',
          slotLabelContent: (arg: SlotLabelContentArg) => {
            // console.log("slot label args", arg);
            if (arg.level == 0) {
              return `${arg.date.getDate()}/${arg.date.getMonth()+1}`;
            } else {
              const dayidx = arg.date.getDay();
              return AgendaComponent.DAYS[dayidx];
            }
          },
          slotLabelClassNames: ["agendaTimelineSlot"],
          allDaySlot: true,
          dateAlignment: "week"

      }
    },
    resources: [
    ],
    eventClick: this.openEventoDialog.bind(this),
    //eventDrop:this.modificaDataEvento.bind(this),
    select: this.openCreaEventoDialog.bind(this),
    eventChange: this.modificaDataEvento.bind(this),
    eventContent: this.renderEventContent.bind(this),
    eventDidMount: this.displayDetails.bind(this), // tasto destro
    //eventColor: '#378006'
    datesSet: this.handleMonthChange.bind(this)

  });

  private backLimit: number[];
  private unsubCurrent: Unsubscribe;

  private utente: Utente;

  constructor(
    private logger: NGXLogger,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public dialogService: DialogService,
    public firestore: AngularFirestore,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private userService:UserService,
    private utentiService:UtentiService,
    private eventiService:EventiService,
    private gruppiService: GruppiTecniciService,
    private auth: AuthService

  ) {

    this.auth.user$.subscribe((user) => {
      if (user != null) {
        this.userService.loadSetUserInformation(user).then(() => {
          this.utente = this.userService.getUser();
          this.loadRisorse();
        });
      }
    });

    this.detectDevice();
    this.dialogConfig = {
      height: "600px",
      width: "600px",
      disableClose: true,
      data: {},
    };

  }


  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }

  ngOnInit(): void {

  }

  refreshVisibleResources () {
    let risorse = this.risorseAtes.filter((risorsa) => {
      return (this.filtroRisorse.length == 0 || this.filtroRisorse.indexOf(risorsa.id) != -1) &&
        (this.filtroGruppi.length == 0 || this.filtroGruppi.indexOf(risorsa.gruppoTecnici?.id) != -1);
    }).map(risorsa => {
      let textColor = "#3F8EFC";
      if(risorsa.ruolo.codice==='ADMIN'){
        textColor = "#3C6E71";
      }else if(risorsa.ruolo.codice==='OPERATORE'){
        textColor = "#8BBF9F";
      }
      return {
          id: ""+risorsa.id,
          title: risorsa.nome.toUpperCase(),
          textColor: textColor,
         };

      });
    this.agendaOptions.update(options => ({
      ...options,
      resources: risorse // Aggiungi gli eventi al segnale
    }));
  }

  loadRisorse(){

    this.gruppiService.get().subscribe((gruppi: GruppoTecnici[]) => {
      console.log("gruppi tecnici: ", gruppi);
      this.gruppiTecnici = [...gruppi];
    });

    this.utentiService.getRisorse().subscribe(
      (risorseFromDb: Utente[]) =>{
        // console.log("risorsedb: ", risorseFromDb);
        this.risorseAtes = [...risorseFromDb.filter((risorsa) => {
          if (this.utente.ruolo.codice == "TECNICO") {
            return risorsa.ruolo.codice == "TECNICO";
          }
          return true;
        })];
        console.log("risorse: ", this.risorseAtes);
        this.refreshVisibleResources();


      }
    );
  }

  getEventoBackgroundColor(categoria):string{
    switch (categoria) {
      case 'INTERVENTO':
        return '#70A0AF';
      case 'MANUTENZIONE':
        return '#C05746';
      case 'GIUSTIFICATIVO':
        return '#F29E4C';
      default:
        return '#A0C1B9';
    }
  }
  getEventoBorderColor(categoria):string{
    switch (categoria) {
      case 'INTERVENTO':
        return '#70A0AF';
      case 'MANUTENZIONE':
        return '#C05746';
      case 'GIUSTIFICATIVO':
        return '#F29E4C';
      default:
        return '#A0C1B9';
    }
  }

  static fullDayFromString (datestring: string) {
    const dmy = datestring.split("/").map(value => parseInt(value));
    const value = new Date(dmy[2], dmy[1] - 1, dmy[0]);
    value.setHours(24, 0, 0);
    return value;
  }

  loadBindEventiCalendario (year: number, month: number) {

    const titles = {
      "INTERVENTO": "INTERVENTO",
      "MANUTENZIONE": "MANUTENZIONE",
      "GIUSTIFICATIVO": "GIUSTIFICATIVO"
    };

    const classes = {
      "INTERVENTO": "evento_intervento",
      "MANUTENZIONE": "evento_manutenzione",
      "GIUSTIFICATIVO": "evento_giustificativo",
      "ALTRO": "evento_altro"
    }

    if (this.unsubCurrent) {
      // console.log("unsubscribing from previous range starting at ", this.backLimit);
      this.unsubCurrent();
    }

    let monthStart = new Date(year, month, 1);
    this.backLimit = [year, month];

    const queryEventi = this.firestore.collection(this.collection_eventi).ref.where('inizio', ">=", monthStart);
    this.unsubCurrent = onSnapshot(queryEventi, (qs) => {
      const eventi: any[] = qs.docs.map((item) => {
        const evento = item.data() as EventoFirestore;
        const endDateFullDay = evento.fineStringa ? AgendaComponent.fullDayFromString(evento.fineStringa) : evento.fine.toDate();
        // console.log(evento.note, evento.fine.toDate(), evento.fineStringa, endDateFullDay);
        //console.log("evento: ", evento);
        let desc = `${evento.note ?? ""}`;
        if (!!evento.codiceSegnalazione) {
          desc = ""+evento["comuneSegnlazione"];
          if ((evento.note ?? "").trim().length > 0) {
            desc += ` (${evento.note.trim()})`;
          }
        }
        return {
            id: evento.idEvento,
            // title: titles[evento.categoria] ?? "ALTRO",
            start: evento.inizio.toDate(),
            end: endDateFullDay,
            resourceId: evento.idTecnico.toString(),
            backgroundColor: this.getEventoBackgroundColor(evento.categoria),
            borderColor: this.getEventoBorderColor(evento.categoria),
            resourceEditable: false,
            allDay: true,
            classNames: ['agenda-event-style', classes[evento.categoria] ?? "evento_altro"],
            _src: evento,
            title: desc
          }
      });

      this.agendaOptions.update(options => ({
        ...options,
        events: eventi
      }));


      // console.log("eventi: ", eventi);
    });

  }

  renderEventContent(arg) {
    const icon = document.createElement('span');
    icon.className = 'material-icons'; // Usando le icone di Material, per esempio
    icon.innerText = 'event'; // Nome dell'icona, ad esempio 'event' per un'icona di calendario

    return { domNodes: [icon] };
  }

  handleMonthChange (info) {
    const startFullDate: Date = info.start;
    const startYear = startFullDate.getFullYear();
    const startMonth = startFullDate.getMonth();

    if ((!this.backLimit) || (this.backLimit[0] > startYear) || ((this.backLimit[0] == startYear) && (this.backLimit[1] > startMonth))) {
      // console.log("changing range start from ", this.backLimit, "to", startYear, startMonth);
      this.loadBindEventiCalendario(startYear, startMonth);
    }



  }

  displayDetails (info: any) {
    const evento: Evento_Firestore = info.event._def.extendedProps["_src"];
    let content:string="";
    if(evento.categoria==='GIUSTIFICATIVO' || evento.categoria==='ALTRO'){
      content+=evento.note?"NOTE: <strong>" + evento.note +"</strong>":'NOTE:';
    }else if(evento.categoria==='MANUTENZIONE' ){

      if(evento.nomeAccompagnatore){
        content+="ACCOMPAGNATO DA: <strong>" + evento.nomeAccompagnatore +"</strong><br>";
      }
      content+= evento.note?"NOTE: <strong>"+evento.note:'</strong>' ;

    }else if(evento.categoria==='INTERVENTO' ){
      content+=evento.areaSegnalazione + " - <strong>"+evento.tipoIntervento+"</strong> a "+evento.comuneSegnlazione +"<br>";
      if(evento.nomeAccompagnatore){
        content+="ACCOMPAGNATO DA: <strong>" + evento.nomeAccompagnatore +"</strong><br>";
      }
      content+= evento.note?"NOTE: <strong>"+evento.note:'</strong>' ;

    }
    if (content.trim().length > 0) {
      tippy(info.el, {
        content: content,
        theme: "light-border",
        allowHTML:true
      });
    }
   /*  = evento.areaSegnalazione + ": " + evento.tipo + " @ " + evento.comune.toUpperCase() + "<br>" ;
      */

      /* const evento: Evento = info.event._def.extendedProps["_src"];
    const content = evento.note ?? "";
    this.logger.info(evento);
    // evitiamo tooltip vuoti che possono confondere?
    if (content.trim().length > 0) {
      tippy(info.el, {
        content: content,
        theme: "light-border"
      }); */
    }





  addContextMenuToEvent(event: any, x: number, y: number){
    const menuItems: MenuItem[] = [
      {
        label: 'Modifica Evento',
        icon: PrimeIcons.PENCIL,
        command: () => this.openEventoDialog({ event })
      },
      {
        label: 'Elimina Evento',
        icon: PrimeIcons.TRASH,
        command: () => this.openEventoDialog(event)
      }
    ];

    this.confirmationService.confirm({
      //target: { x, y },
      message: 'Scegli un\'azione',
      icon: 'pi pi-cog',
      acceptVisible: false,
      rejectVisible: false,
      acceptLabel: '',
      acceptIcon: '',
      rejectLabel: '',
      rejectIcon: '',
      accept: null,
      reject: null,
      acceptButtonStyleClass: 'hidden',
      rejectButtonStyleClass: 'hidden',
      key: 'contextMenu',
    });
  }

  dropEventHandler(eventDropInfo){
   //
    if(eventDropInfo.newResource && eventDropInfo.newResource._resource.id !==eventDropInfo.oldResource._resource.id){
      this.messageService.add({severity:'warn', summary:'', detail: 'Operazione non consentita!', life:3000});
    }
    this.logger.info(eventDropInfo);
  }

  modificaDataEvento(changeInfo: any){

    const calendarSrc: EventDef = changeInfo.oldEvent._def;
    const srcEvent: Evento = calendarSrc.extendedProps["_src"];
    const rangeNew: DateRange = changeInfo.event._instance.range;


    // possiamo spostare eventi

/*
    const today = new Date();
    today.setHours(0, 0, 0);
    if (rangeNew.start < today && rangeNew.end < today) {
      this.messageService.add({severity: "warn", summary: "", detail: "Impossibile spostare eventi nel passato", life: 3000});
      changeInfo.revert();
    }
*/

    const dateForSend = new Date(rangeNew.end);
    dateForSend.setDate(rangeNew.end.getDate()-1);

    this.confirmationService.confirm({
        header: "Modifica pianificazione evento",
        message: "Confermi di voler modificare la data dell'evento?",
        rejectIcon: 'pi pi-times mr-2',
        acceptIcon: 'pi pi-check mr-2',
        rejectButtonStyleClass: 'pButton pRipple p-button-warning',
        acceptButtonStyleClass: 'pButton p-button-success',
        accept: () => {
          const modified = new Evento();
          modified.id = srcEvent.id;
          modified.inizio = rangeNew.start;
          modified.fine = dateForSend;
          // console.log("sending event: ", srcEvent);
          this.eventiService.modifica(modified).subscribe((eventoModificato) => {
            if (Array.isArray(eventoModificato) && eventoModificato.length == 0) {
              changeInfo.revert();
              this.messageService.add({severity:'warn', summary:'', detail: 'Modifica fallita (errore server)!', life:3000});
            } else {
              this.messageService.add({severity:'success', summary:'', detail: 'Evento modificato con successo!', life:3000});
            }
          });
        },
        reject: () => {
          changeInfo.revert();
        }
    });


  }




  openCreaEventoDialog(dataFromAgenda: DateSelectArg){
    //this.logger.info("--> dataFromAgenda "+ dataFromAgenda.resource._resource.id);
/*
    const today = new Date();
    today.setHours(0, 0, 0);
    if (dataFromAgenda.start < today) {
       this.messageService.add({severity: "warn", summary: "", detail: "Non è possibile creare eventi nel passato", life: 3000});
       return;
    }
*/

    const utente = this.userService.getUser();
    if (utente.ruolo.codice == "TECNICO" && dataFromAgenda.resource.id != ""+utente.id) {
      this.messageService.add({severity: "warn", summary: "", detail: "I tecnici non possono creare eventi per altri utenti", life: 3000});
       return;
    }

    dataFromAgenda.end.setDate(dataFromAgenda.end.getDate()-1);
     this.ref = this.dialogService.open(CreaEventoComponent, {
      data: {
        risorsa: dataFromAgenda.resource._resource.id,
        dataInizio:dataFromAgenda.start,
        dataFine:dataFromAgenda.end,

      },
      header: "NUOVO EVENTO PER LA RISORSA "+dataFromAgenda.resource.title.toUpperCase(),
      width:  "99%",
      height: "99%",
      baseZIndex: 10000,
      closable: true,
      draggable: false,
    });
    this.ref.onClose.subscribe((eventCreationResult) => {
      if (eventCreationResult&& eventCreationResult.success) {
        this.messageService.add({severity:'success', summary:'', detail: 'Evento creato con successo!', life:3000});
      }
    });
  }

  openEventoDialog(clickInfo: any){
    console.log("clicked on ", clickInfo);
    this.eventiService.getByID(clickInfo.event._def.publicId).subscribe((evento: Evento) => {
      //this.logger.info(evento);

      console.log("evento scheduler: ", clickInfo.event._def);
      console.log("evento src", evento);

      console.log(evento.inizio);

     this.ref = this.dialogService.open(ModificaEventoComponent, {
        data: {
          evento: evento,
          idTecnico:clickInfo.event._def.resourceIds[0]
        },
        header: "MODIFICA EVENTO",
        width:  "99%",
        height: "99%",
        baseZIndex: 10000,
        closable: true,
        draggable: false,
      });
      this.ref.onClose.subscribe((eventUpdateResult) => {
        if (eventUpdateResult&& eventUpdateResult.success) {
          if(eventUpdateResult.isElimina ){
            this.messageService.add({severity:'success', summary:'', detail: 'Evento eliminato con successo!', life:3000});

          }else {
            this.messageService.add({severity:'success', summary:'', detail: 'Evento modificato con successo!', life:3000});

          }
        }
      });

    });

  }







}
