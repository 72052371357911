import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { SegnalazioniComponent } from './pages/segnalazioni/segnalazioni.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { RoleGuard } from './auth/role.guard';
import { UtentiComponent } from './pages/configurazione/utenti/utenti.component';
import { AreeCompetenzaComponent } from './pages/configurazione/aree-competenza/aree-competenza.component';
import { ComuniComponent } from './pages/configurazione/comuni/comuni.component';
import { InterventiComponent } from './pages/interventi/interventi.component';
import { TipiInterventoComponent } from './pages/configurazione/tipi-intervento/tipi_intervento.component';
import { TipiSegnalazioneComponent } from './pages/configurazione/tipi-segnalazione/tipi_segnalazione.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { GruppiTecniciComponent } from './pages/configurazione/gruppi-tecnici/gruppi_tecnici.component';


const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'utenti',  component: UtentiComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'OPERATORE']},},
  {path: 'aree-competenza',  component: AreeCompetenzaComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'OPERATORE']},},
  {path: 'comuni',  component: ComuniComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'OPERATORE']},},
  {path: 'gruppi-tecnici',  component: GruppiTecniciComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN', 'OPERATORE']},},
  {path: 'tipi-intervento',  component: TipiInterventoComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN']},},
  {path: 'tipi-segnalazione',  component: TipiSegnalazioneComponent, canActivate: [AuthGuard, RoleGuard], data: {allowedRoles: ['ADMIN']},},

  {path: 'segnalazioni',  component: SegnalazioniComponent, canActivate: [AuthGuard, RoleGuard],data: {allowedRoles: ['ADMIN', 'OPERATORE', 'SEGNALATORE']}},
  {path: 'segnalazioni/segnalazione/:segnalazione', component: SegnalazioniComponent, canActivate: [AuthGuard, RoleGuard],data: {allowedRoles: ['ADMIN', 'OPERATORE', 'SEGNALATORE']}},
  {path: 'segnalazioni/stato/:stato', component: SegnalazioniComponent, canActivate: [AuthGuard, RoleGuard],data: {allowedRoles: ['ADMIN', 'OPERATORE', 'SEGNALATORE']} },

  {path: 'interventi',  component: InterventiComponent, canActivate: [AuthGuard, RoleGuard],data: {allowedRoles: ['ADMIN', 'OPERATORE', 'TECNICO']}},
  {path: 'interventi/intervento/:intervento', component: InterventiComponent, canActivate: [AuthGuard, RoleGuard],data: {allowedRoles: ['ADMIN', 'OPERATORE', 'TECNICO']}},
  {path: 'interventi/stato/:stato', component: InterventiComponent, canActivate: [AuthGuard, RoleGuard],data: {allowedRoles: ['ADMIN', 'OPERATORE', 'TECNICO']} },
  {path: 'interventi/segnalazione/:segnalazione', component: InterventiComponent, canActivate: [AuthGuard, RoleGuard],data: {allowedRoles: ['ADMIN', 'OPERATORE', 'TECNICO']}},

  {path: 'agenda',  component: AgendaComponent, canActivate: [AuthGuard, RoleGuard],data: {allowedRoles: ['ADMIN', 'OPERATORE', 'TECNICO']}},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  providers: [
    {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
