<div class="surface-card p-4 shadow-2 border-round">
  <div class="mb-3 flex md:flex-row align-items-center md:justify-content-between justify-content-between ">
    <div class="flex justify-start md:justify-start">
      <span *ngIf="!filterIsSet" class="text-xl font-medium text-2000 align-items-center mr-4" style="color: rgb(10, 57, 29)">INTERVENTI</span>
      <span *ngIf="filterIsSet && filtroStato" class="text-xl font-medium text-2000 align-items-center mr-4" style="color: rgb(48, 100, 197)">INTERVENTI IN STATO:<span style="font-weight: bold;"> {{filtroStato}}</span> </span>
      <span *ngIf="filterIsSet && filtroSegnalazione" class="text-xl font-medium text-2000 align-items-center mr-4" style="color: rgb(106, 187, 25)">INTERVENTI RELATIVI ALLA SEGNALAZIONE <span style="font-weight: bold;">{{filtroSegnalazione}}</span></span>

    </div>

  <div class="flex justify-end md:justify-end">

      <span style="margin-right: 1em">
        <p-button icon="pi pi-download" class="p-button-text mr-1" (click)="downloadAsExcel()"></p-button>
      </span>

      <p-multiSelect class="fieldFilterControl" *ngIf="!filterIsSet"
        [showHeader]="false"
        [filter]="false"
        [options]="opzioniAreeCompetenza"
        display="chip"
        optionLabel="label"
        optionValue="value"
        [(ngModel)]="filtroInterventi.area"
        (onChange)="applyFilters()"
        placeholder="Area di competenza"
        [maxSelectedLabels]="1"
      />


      <p-multiSelect class="fieldFilterControl" *ngIf="!filterIsSet"
        [showHeader]="false"
        [filter]="false"
        [options]="opzioniTipo"
        display="chip"
        [(ngModel)]="filtroInterventi.tipo"
        (onChange)="applyFilters()"
        placeholder="Tipo"
        [maxSelectedLabels]="1"
      />

      <p-multiSelect class="fieldFilterControl" *ngIf="!filterIsSet"
        [showHeader]="false"
        [filter]="false"
        [options]="opzioniStati"
        display="chip"
        optionLabel="label"
        optionValue="value"
        [(ngModel)]="filtroInterventi.stato"
        (onChange)="applyFilters()"
        placeholder="Stato"
        [maxSelectedLabels]="1"
      />

      <p-multiSelect class="fieldFilterControl" *ngIf="!filterIsSet"
        display="chip"
        [options]="opzioniComuni"
        [showToggleAll]="false"
        optionLabel="label"
        optionValue="value"
        [(ngModel)]="filtroInterventi.comune"
        (onChange)="applyFilters()"
        placeholder="Comune"
        [maxSelectedLabels]="1"
      />



<!--
      <span *ngIf="!filterIsSet "
        class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-1"
        style="border-radius: 20px" (click)="menuAree.toggle($event)" pRipple
        [ngClass]="{'bold-text': selectedArea}">
        {{ selectedArea || 'Area' }}<i class="ml-2 pi pi-angle-down text-700"></i>
      </span>
      <p-menu #menuAree [popup]="true" [model]="areeCompetenza"></p-menu>

      <span *ngIf="!filterIsSet "
        class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-1"
        style="border-radius: 20px" (click)="menuTipi.toggle($event)" pRipple
        [ngClass]="{'bold-text': selectedTipo}">
        {{ selectedTipo || 'Tipo' }}<i class="ml-2 pi pi-angle-down text-700"></i>
      </span>
      <p-menu #menuTipi [popup]="true" [model]="tipiIntervento"></p-menu>

      <span *ngIf="!filterIsSet "
        class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-1"
        style="border-radius: 20px" (click)="menuStati.toggle($event)" pRipple
        [ngClass]="{'bold-text': selectedStato}">
        {{ selectedStato || 'Stato' }}<i class="ml-2 pi pi-angle-down text-700"></i>
      </span>
      <p-menu #menuStati [popup]="true" [model]="statiIntervento"></p-menu>

      <span *ngIf="!filterIsSet "
        class="inline-flex cursor-pointer py-2 px-3 align-items-center surface-100 hover:surface-200 transition-colors transition-duration-150 mb-3 md:mb-0 mr-0 md:mr-3 select-1"
        style="border-radius: 20px" (click)="menuComuni.toggle($event)" pRipple
        [ngClass]="{'bold-text': selectedComune}">
        {{ selectedComune || 'Comune' }}<i class="ml-2 pi pi-angle-down text-700"></i>
      </span>
      <p-menu #menuComuni [popup]="true" [model]="comuniIntervento"></p-menu>
-->



     <!--  <div style="display: inline-block; margin-right: 2em">
        <p-dropdown
        [options]="fChoicesComune"
        [(ngModel)]="filtriVisualizzazione.istat_comune"
        [optionLabel]="'label'"
        [optionValue]="'value'"
        (onChange)="applyFilters()"
        placeholder="Comune"
        *ngIf="!filterIsSet "/>

    </div>


    <div style="display: inline-block; margin-right: 2em">

      <p-dropdown
      [options]="fChoicesStato"
      [(ngModel)]="filtriVisualizzazione.stato"
      [optionLabel]="'label'"
      [optionValue]="'value'"
      (onChange)="applyFilters()"
      placeholder="Stato della segnalazione"
      *ngIf="!filterIsSet " />

    </div> -->


    <button *ngIf="filterIsSet" pButton pRipple type="button" icon="pi pi-filter-slash"
          class="py-2 px-3 md:mb-0 mr-0 md:mr-3 p-button-rounded p-button-outlined" style="background-color: rgb(73, 162, 173); color: white;"
          (click)="reloadDataRemovingFilter()" [disabled]="isLoadingResults"
          pTooltip="Rimuovi filtro"></button>
    <span class="p-input-icon-left mr-4" *ngIf="!filterIsSet ">
      <i class="pi pi-search" *ngIf="!filterIsSet "></i>
      <input type="text" pInputText placeholder="Cerca" style="border-radius: 20px;"
             (input)="filtraInterventi($event)" [disabled]="isLoadingResults" *ngIf="!filterIsSet "/>
      </span>

      <button *ngIf="isAdmin || isOperatore" pButton type="button" icon="pi pi-plus"
      pTooltip="Nuovo Intervento"
      class="inline-flex justify-content-center align-items-center mb-1 mr-1"
      style="background-color:#CBEFFF ; color: #007bff; border-color: #CBEFFF; font-family:'Poppins'"
      tooltipPosition="top"
      label="Nuovo Intervento"
      (click)="openCreaInterventoDialog()">
  </button>
      <!-- <button *ngIf="isAdmin || isOperatore " pButton type="button" icon="pi pi-plus" class="p-button-rounded p-button-outlined" style="background-color: rgb(74, 89, 169); color: white;"  tooltipPosition="top" pTooltip="Nuovo Intervento"
        (click)="openCreaInterventoDialog()" [disabled]="isLoadingResults">
      </button> -->
     <!--  <button *ngIf="isAdmin || isSuperAdmin || isSuperVisore || isTecnico" pButton type="button" icon="pi pi-map" class="p-button-rounded p-button-outlined mr-2 ml-2" style="background-color: rgb(9, 175, 197); color: white;"  tooltipPosition="top" pTooltip="Nuova richiesta da Mappa"
      (click)="openCreaSegnalazioneDaMappaDialog()" [disabled]="isLoadingResults">
    </button> -->
    </div>
  </div>

  <p-tabView (onChange)="onTabChange($event)">
    <p-tabPanel header="Elenco" >
        <div fxFlex="95%">
              <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"> </p-confirmDialog>
             <!-- [globalFilterFields]="['id','idRichiesta','creata']"
                 -->
              <p-table #interventiDT styleClass="p-datatable-sm" [value]="interventiFiltrati" dataKey="id" [(selection)]="selectedIntervento"
                       [selectionPageOnly]="true" [rowHover]="true" [rows]="30" [showCurrentPageReport]="true"
                       [rowsPerPageOptions]="[30,50,100]" [loading]="isLoadingResults" responsiveLayout="stack" [paginator]="true"
                       [globalFilterFields]="['id','codice','comune','segnalatore','descrizione']"
                       [sortField]="'modificato'" [sortOrder]="'-1'"
                >

                <ng-template pTemplate="header">
                  <tr>
                    <th style="font-size: 11px;">CODICE</th>
                    <th style="font-size: 11px;">SEGNALAZIONE</th>
                   <!--  <th style="font-size: 11px;">PROVINCIA</th> -->
                    <th style="font-size: 11px;">COMUNE</th>
                   <!--  <th style="font-size: 11px;">CREATO</th> -->
                    <th class="text-center" style="font-size: 11px;">OPERATORE</th>
                    <th class="text-center" style="font-size: 11px;">TECNICI</th>
                    <th class="text-center" style="font-size: 11px;">SCADENZA</th>
                    <th class="text-center" style="font-size: 11px;">PIANIFICATO</th>
                    <th class="text-center" style="font-size: 11px;">ESEGUITO</th>

                    <th class="text-center" style="font-size: 11px;">DURATA</th>
                    <!-- <th >MODIFICATA</th> -->
                    <th style="width: 20%; font-size: 11px;">DESCRIZIONE</th>
                    <th class="text-center" style="font-size: 11px;">AREA</th>
                    <th class="text-center" style="font-size: 11px;">TIPO</th>
                    <th class="text-center" style="font-size: 11px;">STATO</th>
<!--                    <th style="width: 5px;"></th>-->
                    <th style="width: 5px;"></th>
                    <th style="width: 5px;"></th>
                    <th style="font-size: 11px; width: 10px;"></th>

                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-row>
                  <tr>


                    <td> <span class="text-uppercase" style="font-size: 11px;font-weight: 500; font-weight: bold;"  >{{row.label?row.label:row.codice | uppercase}}</span></td>
                    <td> <span class="text-uppercase" style="font-size: 11px;font-weight: 500;"  >{{row.codiceSegnalazione | uppercase}}</span></td>

                    <td> <span class="text-uppercase" style="font-size: 11px;">{{row.comune | uppercase}}</span></td>
                    <td> <span class="text-uppercase" style="font-size: 11px;">{{row.nomeOperatoreInCarico | uppercase}}</span></td>
                    <td>
                      <div *ngFor="let item of row.tecnici" class="text-uppercase" style="font-size: 11px;">
                        {{item.nome | uppercase}}
                      </div>
                    </td>
                    <td class="text-center"> <span class="text-lowercase" style="font-size: 11px; font-weight: bold;">{{row.eseguireEntro?formattaData(row.eseguireEntro):''}}</span></td>
                    <td class="text-center">
                      <span  class="text-lowercase" style="font-size: 11px;">{{getPianificazioneInterventoContent(row)}}</span>
                    </td>


                    <td class="text-center">
                      <span  class="text-lowercase"
                      [ngClass]="getEseguitoColor(row)"
                      style="font-size: 11px; font-weight: bold;">{{row.eseguito?formattaData(row.eseguito):''}}
                    </span>
                    <!--
                      [ngClass]="{
                          'text-green-900': row.eseguito || (!row.eseguireEntro || row.eseguireEntro==='undefined' || row.eseguito <= row.eseguireEntro),
                          'text-red-800': row.eseguito && row.eseguito > row.eseguireEntro
                        }" -
                    < <span *ngIf="!row.eseguireEntro || row.eseguireEntro==='undefined'" class="text-lowercase text-green-900"
                      style="font-size: 11px; font-weight: bold;">{{row.eseguito?formattaData(row.eseguito):''}}
                    </span> -->
                    </td>

                    <td class="text-center">
                      <p-tag *ngIf="row.durata" icon="pi pi-clock" value="{{formattaDurata(row.durata)}}" severity="success" />
                    </td>
                    <td class="text-left"> <span  style="font-size: 10px; font-weight: bold;">{{row.note | uppercase}}</span></td>

                    <td class="text-center">
                      <span
                          class="material-symbols-outlined small-icon"
                          [ngClass]="getAreaCompetenzaColor(row.areaSegnalazione)"
                        [ngStyle]="{ 'color': getAreaCompetenzaColor(row.areaSegnalazione) }"
                          [pTooltip]="row.areaSegnalazione" tooltipPosition="top">
                          {{ stiliService.getMaterialIconAreaCompetenza(row.areaSegnalazione) }}
                        </span>

                      </td>
                    <td class="text-center">  <!--[ngClass]="stiliTipointerventi[row.tipo]!=null?stiliTipointerventi[row.tipo]:stiliTipointerventi['ALTRO']" "-->
                      <span  class="tipo-intervento"
                      style="font-size: 10px;"
                        [style]="{ 'background-color': getTipoInterventoColor(row.tipo) }">
                        {{row.tipo | uppercase}}
                      </span>
                    </td>
                    <td class="text-center"> <span [ngClass]="stiliStatointerventi[row.stato]" style="font-size: 10px;">{{row.stato | uppercase}}</span></td>
<!--                    <td class="text-center" >
                      <i *ngIf="row.tecnici && row.tecnici.length > 0"  class="material-symbols-outlined small-icon" style="color: #0f3d2f; cursor: pointer"
                      pTooltip="{{mostraTecniciIntervento(row)}}"
                        tooltipPosition="top" >
                        group
                      </i>
                    </td>-->
                    <td class="text-center" >
                      <i *ngIf="row.hasRapportino"  class="material-symbols-outlined small-icon" style="color: #0f3d2f; cursor: pointer"
                      pTooltip="Mostra Rapportino"
                        tooltipPosition="top" (click)="openRapportinoIntervento(row)">
                        inventory
                      </i>
                    </td>
                    <td class="text-center" >
                      <i *ngIf="row.allegati"  class="material-symbols-outlined small-icon" style="color: #0f3d2f; cursor: pointer"
                      pTooltip="Mostra Allegati"
                        tooltipPosition="top" (click)="mostraAllegatiIntervento(row)">
                        attach_file
                      </i>
                    </td>

                    <td>
                      <button *ngIf=" showPulsanteAzioni(row)"
                        pButton type="button" style="color:rgb(39, 128, 15)"
                        icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text"
                        (click)="openCaricaAzioni($event,row)">
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
              <p-tieredMenu #menuAzioniTiered [popup]="true" [model]="menuAzioniItems"></p-tieredMenu>
            </div>

    </p-tabPanel>
    <p-tabPanel header="Mappa">

        <div fxFlex="95%" style="height: 68vh">
          <app-activities-map-widget [interventi]="this.interventiFiltrati" [segnalazioni]="this.segnalazioni"></app-activities-map-widget>
        </div>

    </p-tabPanel>
    <p-tabPanel header="Calendario">

      <ng-template pTemplate="content">
        <div fxFlex="95%" style="height: 68vh">
          <div class="field mt-2 mb-2 col-12 calandrContainer">
            <full-calendar [options]='calendarOptions()'>
              <ng-template #eventContent let-arg>
                <b>{{ arg.timeText }}</b>
                <i>{{ arg.event.title }}</i>
              </ng-template>
            </full-calendar>
          </div>
        </div>

      </ng-template>



  </p-tabPanel>
  </p-tabView>

</div>

<p-toast position="top-center"></p-toast>
<p-dialog [(visible)]="mostraConfermaChiusuraIntervento" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}">
  <ng-template pTemplate="header">
      <div class="flex align-items-center">
          <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
              <i class="pi pi-verified text-lg"></i>
          </span>
          <span class="font-medium text-2xl text-900">Comunicazione chiusura intervento</span>
      </div>
  </ng-template>
  <p class="line-height-3 p-0 m-0">
      Confermi di voler comunicare di aver concluso l'intervento?
  </p>
  <ng-template pTemplate="footer">
      <div class=" border-top-1 surface-border pt-3">
          <button pButton pRipple icon="pi pi-times" (click)="mostraConfermaChiusuraIntervento = false" label="Annulla" class="p-button-warning" ></button>
          <button pButton icon="pi pi-check" (click)="confermaChiusuraIntervento()" label="Conferma" class="p-button-success"></button>
      </div>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="mostraConfermaSospensioneIntervento" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}">
  <ng-template pTemplate="header">
      <div class="flex align-items-center">
          <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
              <i class="pi pi-pause-circle text-lg"></i>
          </span>
          <span class="font-medium text-2xl text-900">Sospensione intervento</span>
      </div>
  </ng-template>
  <p class="line-height-3 p-0 m-0">
      Confermi di voler sospendere l'intervento?
  </p>
  <ng-template pTemplate="footer">
      <div class=" border-top-1 surface-border pt-3">
          <button pButton pRipple icon="pi pi-times" (click)="mostraConfermaSospensioneIntervento = false" label="Annulla" class="p-button-warning" ></button>
          <button pButton icon="pi pi-check" (click)="confermaSospensioneIntervento()" label="Conferma" class="p-button-success"></button>
      </div>
  </ng-template>
</p-dialog>
<p-dialog [(visible)]="mostraConfermaSbloccaIntervento" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '40vw'}">
  <ng-template pTemplate="header">
      <div class="flex align-items-center">
          <span class="flex align-items-center justify-content-center bg-cyan-100 text-cyan-800 mr-3 border-circle" style="width:32px;height:32px">
              <i class="pi pi-play text-lg"></i>
          </span>
          <span class="font-medium text-2xl text-900">Riattivazione intervento</span>
      </div>
  </ng-template>
  <p class="line-height-3 p-0 m-0">
      Confermi di voler riattivare l'intervento?
  </p>
  <ng-template pTemplate="footer">
      <div class=" border-top-1 surface-border pt-3">
          <button pButton pRipple icon="pi pi-times" (click)="mostraConfermaSbloccaIntervento = false" label="Annulla" class="p-button-warning" ></button>
          <button pButton icon="pi pi-check" (click)="confermaSbloccoIntervento()" label="Conferma" class="p-button-success"></button>
      </div>
  </ng-template>
</p-dialog>
