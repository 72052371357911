

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { TipoSegnalazione } from "src/app/models/tipoSegnalazione.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { TipiSegnalazioneService } from "src/app/services/tipiSegnalazione.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  selector: "crea-tipo_segnalazione",
  templateUrl: "./dialog-crea-tipo_segnalazione.component.html",
  styleUrls: ["../tipi_segnalazione.component.css"],
})
export class CreaTipoSegnalazioneComponent implements OnInit {
  protected _onDestroy = new Subject<void>();

  public creaTipoSegnalazioneForm: FormGroup;
  protected areeCompetenza:AreaCompetenza[]=[];

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private areaCompetenzaService:AreeCompetenzaService,
    private tipiSegnalazioneService:TipiSegnalazioneService,
  ) {

  }

  ngOnInit(): void {
    this.loadAreeCompetenza();
    this.creaTipoSegnalazioneForm = this._formBuilder.group({
      areaCompetenza :          [null, [Validators.required]],
      label:                    [null, [Validators.required]],
      giorniRisoluzione:        [null, [Validators.required]],
      colore:                   [null, [Validators.required]],

    });
  }

  loadAreeCompetenza(){
    this.areaCompetenzaService.get().subscribe(areeCompetenzaFromDB =>{
      this.areeCompetenza = areeCompetenzaFromDB;
    });
  }

  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let tipoSegnalazioneDaCreare: TipoSegnalazione = new TipoSegnalazione();
          tipoSegnalazioneDaCreare.label                = this.creaTipoSegnalazioneForm.value.label;
          tipoSegnalazioneDaCreare.colore               = this.creaTipoSegnalazioneForm.value.colore;
          tipoSegnalazioneDaCreare.areaCompetenza       = this.creaTipoSegnalazioneForm.value.areaCompetenza;
          tipoSegnalazioneDaCreare.giorniRisoluzione    = this.creaTipoSegnalazioneForm.value.giorniRisoluzione;

       this.tipiSegnalazioneService.crea(tipoSegnalazioneDaCreare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di creazione del tipo di segnalazione!", life:10000
          });
        }
      );
    }
  }
}
