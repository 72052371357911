import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {catchError, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import { GruppoTecnici } from '../models/gruppoTecnici.model';


@Injectable({
  providedIn: 'root',
})
export class GruppiTecniciService{
  apiUrl: string = environment.apiUrl +"gruppo-tecnici";

  constructor(
    private http: HttpClient,
    private logger: NGXLogger

    ) {}


  get(): Observable<any> {
    const headers = {
     // 'Authorization': `Bearer ${token}`,
    };

    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.get(this.apiUrl, httpOptions);
  }


  crea(gruppoTecnici:GruppoTecnici): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.post(this.apiUrl, gruppoTecnici, httpOptions);

  }


  modifica(gruppoTecnici:GruppoTecnici): Observable<any> {

    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.put(this.apiUrl+"/"+gruppoTecnici.id, gruppoTecnici, httpOptions);
  }

  elimina(idTgruppoTecnici:string): Observable<any> {
    const headers = {
    //  'Authorization': `Bearer ${token}`,
    };
    const httpOptions = {
      headers,
      reportProgress: true,
    };
    return this.http.delete(this.apiUrl+"/"+idTgruppoTecnici, httpOptions);

  }




}
