

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MessageService } from "primeng/api";


import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { ComuneDTO } from "src/app/models/comune.model";
import { GruppoTecnici } from "src/app/models/gruppoTecnici.model";
import { Utente } from "src/app/models/utente.model";
import { RuoloUtente } from "src/app/models/utente.ruolo.model";
import { ComuniService } from "src/app/services/comuni.service";
import { GruppiTecniciService } from "src/app/services/gruppiTecnici.service";
import { UtentiService } from "src/app/services/utenti.service";
import { UtilService } from "src/app/services/util.service";


@Component({
  templateUrl: "./dialog-modifica-utente.component.html",
  styleUrls: ["../utenti.component.css"],
})
export class ModificaUtenteComponent implements OnInit {
  protected _onDestroy = new Subject<void>();
  protected ruoli: RuoloUtente[];
  protected comuni: ComuneDTO[]= [];
  protected gruppiTecnici: GruppoTecnici[]= [];

  public modificaUtenteForm: FormGroup;
  public utenteSelezionato: Utente;

  public isRuoloSegnalatore: Boolean = false;
  public isRuoloTecnico: Boolean = false;

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private utentiService: UtentiService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private utilService:UtilService,
    private comuniService:ComuniService,
    private gruppiTecniciService:GruppiTecniciService,


  ) {

  }

  ngOnInit(): void {
    this.utenteSelezionato = this.config.data.utente;
    this.loadRuoliUtente();
    if(this.utenteSelezionato.ruolo.codice === 'SEGNALATORE'){
      this.isRuoloSegnalatore = true;
      this.loadComuni();
    }else if(this.utenteSelezionato.ruolo.codice === 'TECNICO'){
      this.isRuoloTecnico = true;
      this.loadGruppiTecnici();
    }
    this.modificaUtenteForm = this._formBuilder.group({
      nome:         [ this.utenteSelezionato.nome, [Validators.required]],
      ruolo:        [this.utenteSelezionato.ruolo, [Validators.required]],
      comune:         [this.utenteSelezionato.comune],
      gruppoTecnici:  [this.utenteSelezionato.gruppoTecnici],
    });


  }

  loadRuoliUtente(){
    this.utilService.getRuoliUtente().subscribe(ruoliFromDB=>{
      this.ruoli = ruoliFromDB;
    });
  }

  loadComuni(){
    this.comuniService.get().subscribe(comuniFromDB=>{
      this.comuni = comuniFromDB;
    });
  }
  loadGruppiTecnici(){
    this.gruppiTecniciService.get().subscribe(gruppiTecniciDB=>{
      this.gruppiTecnici = gruppiTecniciDB;
    });
  }

  getRuoloClass(ruolo: string): string {
    if (ruolo === "ADMIN") {
      return "ruolo-utente  ruolo-utente-admin";
    }else if (ruolo === "SEGNALATORE") {
      return "ruolo-utente  ruolo-utente-segnalatore";
    }else if (ruolo === "OPERATORE") {
      return "ruolo-utente  ruolo-utente-operatore";
    }else if (ruolo === "TECNICO") {
      return "ruolo-utente  ruolo-utente-tecnico";
    }
    return "";
  }

  onRuoloSelection(event) {
    this.logger.info(event.value);
    let ruoloSelezionato:RuoloUtente = event.value;
    if(ruoloSelezionato.codice==="SEGNALATORE"){
      this.isRuoloSegnalatore = true;
      this.isRuoloTecnico = false;

      if(this.comuni.length==0){
        this.loadComuni();
      }
    }else  if(ruoloSelezionato.codice==="TECNICO"){
      this.isRuoloTecnico = true;
      this.isRuoloSegnalatore = false;
      if(this.gruppiTecnici.length==0){
        this.loadGruppiTecnici();
      }
    }else{
      this.isRuoloSegnalatore = false;
      this.isRuoloTecnico = false;

    }

    //this.colonne = this.colonne.filter(item => item !== event.value);
   }



  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let utenteDaModificare: Utente = new Utente();
      utenteDaModificare.id = this.utenteSelezionato.id;
      utenteDaModificare.nome = this.modificaUtenteForm.value.nome;
      utenteDaModificare.ruolo = this.modificaUtenteForm.value.ruolo;
      utenteDaModificare.comune = this.modificaUtenteForm.value.comune;
      utenteDaModificare.gruppoTecnici = this.modificaUtenteForm.value.gruppoTecnici;
      this.utentiService.modifica(utenteDaModificare).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          console.error("ERRORE MODIFICA UTENTE" + error);
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di modifica utente!", life:10000
          });
        }
      );
    }
  }
}
