import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { ConfirmationService, MenuItem, Message, MessageService, PrimeIcons } from "primeng/api";
import { environment } from '../../../../environments/environment';
import { debounceTime, distinctUntilChanged, of, Subject, switchMap, Observable } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ComuneDTO } from "src/app/models/comune.model";
import { SegnalazioniService } from "src/app/services/segnalazioni.service";
import { UtentiService } from "src/app/services/utenti.service";
import { ComuniService } from "src/app/services/comuni.service";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { Segnalazione } from "src/app/models/segnalazione.model";
import { UserService } from "src/app/services/user.service";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { TipoSegnalazione } from "src/app/models/tipoSegnalazione.model";
import { AreeCompetenzaService } from "src/app/services/areeCompetenza.service";
import { TipiInterventoService } from "src/app/services/tipiIntervento.service";
import { TipiSegnalazioneService } from "src/app/services/tipiSegnalazione.service";
import { SostegniMapWidgetComponent } from "src/app/components/sostegni-map-widget/sostegni-map-widget.component";
import { SostegniService } from "src/app/services/sostegni.service";
import { Sostegno } from "src/app/models/sostegno.models";
import { NominatimService } from "src/app/services/nominatim.service";
import { NominatimResponse } from "src/app/models/nominatim/response.model";
import { SimpleMapWidgetComponent } from "src/app/components/simple-map-widget/simple-map-widget.component";
import { Coordinate } from "src/app/models/coordinate.model";
import { GoogleStorageService } from "src/app/services/googleStorage.service";
import {
  LocalizeActivityMapWidgetComponent,
  PosizioneSegnalazione
} from "../../../components/localize-activity-map-widget/localize-activity-map-widget.component";
import {ActivitiesMapWidgetComponent} from "../../../components/activities-map-widget/activities-map-widget.component";

@Component({
  selector: "crea-segnalazione",
  templateUrl: './crea-segnalazione.component.html',
  styleUrls: ['../segnalazioni.component.css']
})
export class CreaSegnalazioneComponent implements OnInit, OnDestroy {

  @ViewChild(LocalizeActivityMapWidgetComponent) siglocmap: LocalizeActivityMapWidgetComponent;

  protected _onDestroy = new Subject<void>();

  protected creaSegnalazioneForm: FormGroup;
  protected posizioneForm: FormGroup;
  protected ref: DynamicDialogRef;

  protected comuni:ComuneDTO[]=[];
  protected filteredComuni: any[] =[];
  protected showComuni:Boolean = false;
  protected isSegnalatore:Boolean = false;
  protected sostegnoSelezionato:Sostegno;

  protected pagineCreaSegnalazione: MenuItem[];
  protected activeIndex: number = 0;

  protected areeCompetenza:AreaCompetenza[]=[];
  protected tipiSegnalazione:TipoSegnalazione[]=[];

  //protected indirizzi: Observable<any[]> = of([]);
  protected indirizzi:NominatimResponse[]=[];
  protected indirizziObs:Observable<NominatimResponse[]>=of([]);
  filteredIndirizzi: any[];
  protected selectedFiles: File[] = [];
  protected isFileUploaded: boolean = false;

  // aggiunto per evitare discrepanze tra autocomplete e input disattivato se l'utente è segnalatore
  protected selectedComune: ComuneDTO;

  protected mapPosition: PosizioneSegnalazione;
  protected showSostegni: boolean;

  @HostBinding("class.mobile") isMobile: boolean;
  constructor(
      private logger: NGXLogger,
      public dialogRef: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private _formBuilder: FormBuilder,
      private segnalazioniService: SegnalazioniService,
      private utenteService:UtentiService,
      public dialogService: DialogService,
      private comuniService: ComuniService,
      private messageService: MessageService,
      protected userService:UserService,
      protected areeCompetenzaService:AreeCompetenzaService,
      protected tipiSegnalazioneService:TipiSegnalazioneService,
      private sostegniService:SostegniService,
      private nominatimService: NominatimService,
      private googleStorageService: GoogleStorageService,

    ) {
      this.detectDevice();
      this.mapPosition = null;
  }

  detectDevice() {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    this.isMobile = isMobile;
  }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.isSegnalatore = this.userService.isSegnalatore;
    this.logger.info("1 ngOnInit "+  this.isSegnalatore );

    if (this.isSegnalatore) {
      this.selectedComune = this.userService.getUser().comune;
    }

    //this.loadUtente();
    this.loadComuni();
    this.loadAreeCompetenza();
    this.inizializzaSteps();
    this.creaSegnalazioneForm = this._formBuilder.group({
      autore:         new FormControl({value: this.userService.getUser(), disabled: false}, Validators.required),
      //comune:         new FormControl({value: this.userService.getUser().comune?this.userService.getUser().comune.denominazione:null, disabled: this.userService.getUser().comune?true:false}, Validators.required),
      comune:         new FormControl({value: this.userService.getUser().comune ? this.userService.getUser().comune.denominazione : null, disabled: true}, Validators.required),
      areaCompetenza: new FormControl({value: null, disabled: false}, Validators.required),
      tipo:           new FormControl({value: null, disabled: true}, Validators.required),
      descrizione:    [null],
      segnalatore:    [null],
    });

    this.posizioneForm = this._formBuilder.group({
      indirizzo:    [null],
      sostegno:     [null],
      coordinate:   [null],
    });

    this.creaSegnalazioneForm.valueChanges.subscribe(() => {
      this.showSostegni = this.creaSegnalazioneForm.get("areaCompetenza")?.value?.label == "PUBBLICA" ?? false;
    });

  }

  geocodificaIndirizzo(event: any){
    const query = event.query;

    this.nominatimService.searchAddress(query, this.creaSegnalazioneForm.get("comune").value).subscribe((results: NominatimResponse[]) => {
      this.filteredIndirizzi = results.map(result => ({
        indirizzo: result.display_name.split(',')[0].trim(),
        latitudine: result.lat,
        longitudine: result.lon
      }));
    });
  }

  onIndirizzoSelect(event: any) {

    //this.posizioneForm.get("coordinate")?.setValue(event.value.latitudine.valueOf(), event.value.longitudine.valueOf());
    let coordinate:Coordinate = new Coordinate();
    coordinate.latitudine =event.value.latitudine.valueOf();
    coordinate.longitudine =event.value.longitudine.valueOf();
    this.posizioneForm.get("coordinate")?.setValue(coordinate);

  }


  loadAreeCompetenza(){
    if(this.userService.getUser().comune){
      this.areeCompetenza = this.userService.getUser().comune.areeCompetenza;
    }else{
      this.areeCompetenzaService.get().subscribe(areeCompetenzaFromDB =>{
        this.areeCompetenza = areeCompetenzaFromDB;
      });
    }
  }
  loadUtente(){
    this.utenteService.getByIdAuth0(localStorage.getItem("idUtente")).subscribe(utente =>{

    });
  }

  onAreaCompetenzaSelect(event:any){
    this.tipiSegnalazioneService.getByAreaCompetenza(event.value.id).subscribe(tipiSegnalazioneFromDB=>{
      this.tipiSegnalazione = tipiSegnalazioneFromDB;
    });
    this.creaSegnalazioneForm.get('tipo')?.enable();
  }


  changePage() {
    this.activeIndex = this.activeIndex + 1;
    // console.log("Sel comune", this.creaSegnalazioneForm.controls["comune"].value ?? null);
    if (this.activeIndex === 2){
      console.log("info geografiche segnalazione", this.posizioneForm.value);
      //this.getFogliComune(this.selectedComuneCatastale.codiceCatastaleIntero, this.selectedProvincia.codice);
    } else if (this.activeIndex === 1) {
      console.log("WIDGET: ", this.siglocmap);
    }
  }
  checkNextStep(): boolean {
    let disableNextStepButton = false;
    if (this.activeIndex === this.pagineCreaSegnalazione.length - 1) {
      disableNextStepButton = true;
    } else if (this.activeIndex === 1) {
      disableNextStepButton = !(this.posizioneForm.get("coordinate")?.value);
    } else if (this.activeIndex === 0 && !this.creaSegnalazioneForm.valid) {
      disableNextStepButton = true;
    }
    return disableNextStepButton;
  }

  inizializzaSteps() {
    this.pagineCreaSegnalazione = [
      {
        label: "Dettagli",
      },
      {
        label: "Localizzazione",
      },
      {
        label: "Allegati",
      }
    ];
    this.activeIndex = 0;
  }



 onComuneSelect(comune: ComuneDTO){
    // console.log("selecting comune ", comune);
    this.selectedComune = comune;
    this.creaSegnalazioneForm.get("comune").setValue(comune);
  }


  loadComuni() {
    this.comuniService.get().subscribe((res) => {
      this.comuni = res;
      if(!this.isSegnalatore){
        this.creaSegnalazioneForm.get("comune").enable();
      }

    });
  }

  filterComune(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.comuni.length; i++) {
        let comune = this.comuni[i];
        if (comune.denominazione.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          filtered.push(comune);
        }
    }
    this.filteredComuni = filtered;
  }


  onFileUpload($event) {
    this.logger.info($event);
    this.selectedFiles = $event.files;
    if(this.selectedFiles.length > 0){
      this.isFileUploaded = true;
    }
   /*
    this.selectedFile = $event.files[0]; */
  }
  onFileRemove() {
    if(this.selectedFiles.length == 0){
      this.isFileUploaded = false;
    }

  }


  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let segnalazione:Segnalazione = new Segnalazione();

      segnalazione.autore           = this.userService.getUser();
      // segnalazione.comune           = this.creaSegnalazioneForm.get("comune").value;
      segnalazione.comune           = this.selectedComune;
      segnalazione.descrizione      = this.creaSegnalazioneForm.get("descrizione").value;
      segnalazione.segnalatore      = this.creaSegnalazioneForm.get("segnalatore").value?this.creaSegnalazioneForm.get("segnalatore").value:null;
      segnalazione.tipoSegnalazione = this.creaSegnalazioneForm.get("tipo").value;

      // rimosso civico perché è integrato nell'output della mappa
      segnalazione.indirizzo        = this.posizioneForm.get("indirizzo").value.indirizzo;
      this.logger.info(this.posizioneForm.get("indirizzo").value);

      if(this.posizioneForm.get("sostegno")){
        //this.logger.info(this.posizioneForm.get("sostegno").value);
        segnalazione.sostegno = this.posizioneForm.get("sostegno").value;
        segnalazione.indirizzo        = this.posizioneForm.get("indirizzo").value;

      }
      if(this.posizioneForm.get("coordinate")){
        this.logger.info(this.posizioneForm.get("coordinate").value);
        segnalazione.coordinate = {...this.posizioneForm.get("coordinate").value};
      }


      if(this.isFileUploaded){
        segnalazione.allegato = true;

      }




     this.segnalazioniService.crea(segnalazione).subscribe(segnalazioneCreata => {
      if(this.isFileUploaded){
        this.logger.info(segnalazioneCreata);

        //TODO: CARICA FILE SU STORAGE
          this.selectedFiles.forEach(allegato => {
            this.googleStorageService.uploadAllegatoSegnalazioneToGoogleStorage(allegato,segnalazioneCreata.codice);

          });
          this.dialogRef.close({ success: true});
      }else{
        this.dialogRef.close({ success: true});
      }


      },
      error => {
        console.error('ERRORE CREAZIONE SEGNALAZIONE' + error);
        this.dialogRef.close({
          success: false
        });
      });



    }
  }

  updatePosition (position: PosizioneSegnalazione) {
    this.mapPosition = position;
    console.log("position received from map component: ", position);
    this.posizioneForm.get("sostegno")?.setValue(position.sostegno ?? null);
    this.posizioneForm.get("indirizzo")?.setValue(position.indirizzo ?? "");
    this.posizioneForm.get("coordinate")?.setValue(position.coordinates);
  }
}
