<form [formGroup]="creaAreaCompetenzaForm">
    <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12">
            <label for="label" class="font-medium text-900">IDENTIFICATIVO</label>
            <input id="label" type="text" type="label" formControlName="label" pInputText>
        </div>
        <div class="field mb-3 col-12">
          <label for="colore" class="font-medium text-900 mr-4">COLORE</label>
          <p-colorPicker id="colore" formControlName="colore" />
      </div>

    </div>
</form>
<div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>

<div class="margin-top-1" style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <p-button icon="pi pi-check" (onClick)="conferma(true)" label="Conferma" class="p-button-text mr-1"
        styleClass="p-button-success"
        [disabled]="!creaAreaCompetenzaForm.valid"></p-button>
    <p-button icon="pi pi-times" (onClick)="conferma(false)" label="Annulla" class="p-button-text ml-1"
        styleClass="p-button-warning"></p-button>
</div>
