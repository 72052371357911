

import {
  Component,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NGXLogger } from "ngx-logger";
import { MessageService } from "primeng/api";


import {
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import { Subject } from "rxjs";
import { AreaCompetenza } from "src/app/models/areaCompetenza.model";
import { GruppoTecnici } from "src/app/models/gruppoTecnici.model";
import { GruppiTecniciService } from "src/app/services/gruppiTecnici.service";


@Component({
  selector: "crea-gruppo_tecnici",
  templateUrl: "./dialog-crea-gruppo_tecnici.component.html",
  styleUrls: ["../gruppi_tecnici.component.css"],
})
export class CreaGruppoTecniciComponent implements OnInit {
  protected _onDestroy = new Subject<void>();

  public creaGruppoTecniciForm: FormGroup;
  protected areeCompetenza:AreaCompetenza[]=[];

  constructor(
    private logger: NGXLogger,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private messageService: MessageService,
    private _formBuilder: FormBuilder,
    private gruppoTecniciService:GruppiTecniciService,
  ) {

  }

  ngOnInit(): void {
    this.creaGruppoTecniciForm = this._formBuilder.group({
      descrizione :          [null, [Validators.required]]
    });
  }



  conferma(conferma: boolean) {
    if (!conferma) {
      this.dialogRef.close({ success: false });
    } else {
      let gruppoTecnici: GruppoTecnici = new GruppoTecnici();
          gruppoTecnici.descrizione                = this.creaGruppoTecniciForm.value.descrizione;

       this.gruppoTecniciService.crea(gruppoTecnici).subscribe(
        (res) => {
          this.dialogRef.close({ success: true });
        },
        (error) => {
          this.messageService.add({
            severity: "error",
            summary: "ATTENZIONE",
            detail: "Errore in fase di creazione del gruppo tecnici!", life:10000
          });
        }
      );
    }
  }
}
