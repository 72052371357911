import { AreaCompetenza } from "./areaCompetenza.model";

export class TipoSegnalazione {
    id: number;
    label: string;
    colore: string;
    areaCompetenza:AreaCompetenza;
    deletable:boolean;
    giorniRisoluzione: number;
  }
